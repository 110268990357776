import { Content } from "Common/UI/Molecules";
import React, { useEffect, useState } from "react";
import { AdminWrapper, ListWrapper } from "../../styled.Performance.Photograph";
import { Link } from "react-router-dom";
import { Grid } from "Common/UI/Atoms";
import PhotographBody from "../../Components/Photograph.Body";
import usePhotograph from "../../usePhotograph";
import moment from "moment";
import { useCookies } from "react-cookie";

const PerformancePhotographList = () => {
    const { state, onLoad } = usePhotograph();
    const [cookies] = useCookies();
    const isAdmin = !!cookies?.username;
    useEffect(() => {
        onLoad();
    }, []);

    return (
        <>
            <Content title={"공사 사진"} />
            {isAdmin ? (
                <AdminWrapper align={"right"} width={1200}>
                    <Link to={"./write"}>글 작성</Link>
                </AdminWrapper>
            ) : undefined}
            <ListWrapper width={1200} gap={[60, 40]}>
                {state?.map((i, key) => (
                    <PhotographBody
                        id={i?.idx}
                        title={i?.title}
                        url={i?.url}
                        company={i?.company}
                        date={moment(i?.date, "YYYY-MM-DD").format(
                            "YYYY년 MM월"
                        )}
                        key={key}
                    />
                ))}
            </ListWrapper>
        </>
    );
};

export default PerformancePhotographList;
