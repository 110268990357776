import { Flex, Icon } from "Common/UI/Atoms";
import React from "react";
import { ImageWrapepr } from "../../../styled.Performance.Photograph";

const ImageInput = ({ isEdit, state, onChange, onDelete }) => {
    return (
        <ImageWrapepr $isEdit={isEdit}>
            <img src={state?.url} alt="" />
            <Flex className={"comment"} column valign="bottom">
                {isEdit ? (
                    <button onClick={onDelete}>
                        <Icon.Closer fill={"#fff"} />
                    </button>
                ) : undefined}
                {isEdit ? (
                    <textarea
                        type="text"
                        name={"comment"}
                        value={state?.comment}
                        onChange={onChange}
                        placeholder="설명글을 입력하세요"
                    />
                ) : (
                    <p>{state?.comment}</p>
                )}
            </Flex>
        </ImageWrapepr>
    );
};

export default ImageInput;
