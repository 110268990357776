import { onDisplaySetting } from "Store/Common/CommonSlice";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useDisplay = () => {
    const [state, setDisplay] = useState({
        pc: window.outerWidth > 768,
        mobile: window.outerWidth <= 768,
    });

    const { display } = useSelector(({ common }) => common);
    const dispatch = useDispatch();
    const ref = useRef(state);
    const displayRef = useRef(display);

    useEffect(() => {
        ref.current = state;
    }, [state]);

    useEffect(() => {
        displayRef.current = display;
    }, [display]);

    const onDisplay = () => {
        const state = ref.current;
        const display = displayRef.current;
        const width = window.outerWidth;
        if (width > 768 && (!state.pc || !display?.pc)) {
            setDisplay({ pc: true, mobile: false });
            dispatch(onDisplaySetting({ pc: true, mobile: false }));
        } else if (width <= 768 && (!state.mobile || !display?.mobile)) {
            setDisplay({ pc: false, mobile: true });
            dispatch(onDisplaySetting({ pc: false, mobile: true }));
        }
    };

    useEffect(() => {
        onDisplay();
        window.addEventListener("resize", onDisplay, {
            passive: true,
        });
        return () => {
            window.removeEventListener("resize", onDisplay, {
                passive: true,
            });
        };
    }, []);

    return { display: state };
};

export default useDisplay;
