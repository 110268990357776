import React from "react";
import { Route, Routes } from "react-router-dom";
import Empty from "Pages/Empty/Empty";
import { COMPANYS_CEO, COMPANYS } from "./Route.Companys";
import { CompanysData } from "./Data.Companys";

const CompanysRouter = () => {
    const replaceUrl = (url) => url.replace(COMPANYS, "");

    return (
        <Routes>
            <Route path={"*"} element={<Empty url={COMPANYS_CEO} />} />
            {CompanysData?.map(({ path, Element }, key) => (
                <Route
                    path={replaceUrl(path)}
                    element={<Element />}
                    key={key}
                />
            ))}
        </Routes>
    );
};

export default CompanysRouter;
