import React from "react";
import { MainLayout } from "./styled.Main";
import MainBanner from "./Pages/Banner/Main.Banner";
import MainCompanys from "./Pages/Companys/Main.Companys";
import MainInquiry from "./Pages/Inquiry/Main.Inquiry";
import Footer from "Pages/Footer/Footer";

const MainMobile = () => {
    const elements = [
        MainBanner,
        MainCompanys,
        // MainPerformance,
        MainInquiry,
        Footer,
    ];
    return (
        <MainLayout className="mo">
            {elements?.map((Element, key) => (
                <Element key={key} />
            ))}
        </MainLayout>
    );
};

export default MainMobile;
