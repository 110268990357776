import { Flex } from "Common/UI/Atoms";
import { css, styled } from "styled-components";


export const Wrapper = styled(Flex)`
    width:1200rem;
    margin-bottom:180rem;
    @media (max-width:768px){
        width:100%;
        padding:0 16rem;
        margin-bottom:40rem;
    }
`

export const TitleWrapper = styled.h2`${({ $color }) => css`
    display:flex;
    align-items:center;
    height:62rem;
    font-weight:500;
    font-size:22rem;
    color:${$color};
    border-bottom:1rem solid;
    padding-left:8rem;
    border-bottom-color:${$color};
`}
`

export const ValueWrapper = styled(Flex)`
    gap:20rem;
    svg{
        flex:1;
    }
    @media (max-width:768px){
        flex-direction:column;
        svg{
            width:100%;
        }
    }
`