import { Flex } from "Common/UI/Atoms";
import { headerHeight } from "Common/Utils/Utils";
import { css, styled } from "styled-components";

export const Wrapper = styled.div`
    background:rgba(0,0,0,0.8) ;
    display: flex;
    flex-direction:column;
    justify-content:center;
    position:relative;
    .content{
        color:#fff;
        h5{
            font-size:28rem;
            font-weight:500;
            @media (min-width:769px){
                margin-bottom:16rem;
            }
            @media (max-width:768px){
                margin-bottom:8rem;
                font-size:22rem;
            }
        }
        h2{
            font-size:60rem;
            font-weight:600;
            @media (max-width:768px){
                font-size:36rem;
            }
            span{
                display: contents;
                font-weight:400;
            }
        }
        p{
            font-size:20rem;
            white-space:pre-line;
            @media (max-width:768px){
                font-size:14rem;
            }
        }
    }
    @media (min-width:769px){
        padding:0 240rem;

    }
    @media (max-width:768px){
        height:calc(100vh - ${headerHeight}rem);
        min-height: 500rem;
    }
`
export const ContentWrapper = styled(Flex).attrs({
    column: true,
    valign: 'middle',
})`
${({ $isFocus }) => css`
    position:absolute;
    background-color:rgba(0,0,0,0.1) ;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:${$isFocus ? 2 : 1};
    @media (min-width:769px){
        padding:0 240rem;
    }
    @media (max-width:768px){
        padding:0 32rem;
    }
    img{

        filter:blur(${$isFocus ? `0px` : `5px`});
        opacity:${$isFocus ? 0.8 : 0};
        transition:opacity 500ms, filter 500ms;
    }
    &::before{
        content:'';
        position:absolute ;
        z-index:-2;
        background:#000 ;
        left:0;
        top:0;
        width:100%;
        height:100%;
    }
    img{
        left:0;
        position: absolute;
        width:100%;
        height:100%;
        object-fit:cover;
        z-index:-1;
        opacity:1;
    }
`}
`

export const GaugeWrapper = styled.div`${({ $timer, $gauge }) => css`
    width:600rem;
    height:4rem ;
    background-color:rgba(255,255,255,0.3);
    position:relative;
    margin-top:200rem;
    border-radius:100vw;
    z-index:2;
            @media (max-width:768px){
                width:60%;
                margin-left:36rem;
            }
    &::after{
        content:'';
        position:absolute;
        width:${$gauge}%;
        height:100%;
        background:#fff; 
        border-radius:100vw;
        transition: width ${$timer / 100}ms ;
    }
`}
`