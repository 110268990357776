import { Flex } from "Common/UI/Atoms";
import { styled } from "styled-components";

export const CompanyWrapper = styled(Flex).attrs({ align: 'center', valign: "middle" })`
    background-color:#333;
    &::before,&::after,.content::after,.content::before{
        content:'';
        position:absolute;
        background-color: rgba(255,255,255,0.12);
    }
    &::before,&::after{
        width:calc(100% - 40rem) ;
        height:10rem;
        left:20rem;
    }
    .content::after,.content::before{
        z-index:3;
        width:10rem;
        height:calc(100% - 100rem) ;
        top:50rem;
    }
    
    &::after{
        top:40rem;
    }
    &::before{
        bottom:40rem;
    }
    
    .content::after{
        left:20rem;
    }
    .content::before{
        right:20rem;
    }
    .content{
        z-index:1;
        width:100%;
        height:100%;
            padding:120rem 0 70rem;
        @media (max-width:768px){
            padding:60rem 0;
        }
        .contentHeader{
            color:#fff;
            padding: 0 240rem;
        @media (max-width:768px){
            padding:0 36rem;
        }
            h2{
                span{
                    font-weight:400;
                }
            }
        }
    }
        @media (max-width:768px){
            .contentScroll{
                width:100%;
                overflow-x:scroll ;
            }
        }
    .contentBody{
            width:100%;
        @media (max-width:768px){
            width:max-content;
            height:360rem;
        }
    }
`

export const DetailWrapper = styled(Flex).attrs({
    align: "center",
    valign: "middle",
})`
    overflow: hidden;
        @media (max-width:768px){
            min-width:280rem;
            height:360rem;
        }
    img{
        position:absolute;
        width:100%;
        height:100%;
        object-fit:cover;
        transition: transform 1000ms ;
    }
    .title{
        z-index:1;
        color:#fff;
        justify-content:flex-end;
        h6{
            font-size:18rem;
            font-weight:500;
            line-height:31rem;
            margin-bottom:12rem;
        @media (max-width:768px){
            font-size:14rem;
            line-height:1.6;
            margin-bottom:8rem;
        }
    }
        h3{
            font-size:32rem;
            font-weight:600;
        @media (max-width:768px){
            font-size:20rem;
        }

        }
        p{
            font-size:18rem;
            white-space:pre-line;
            margin-bottom:28rem;
            
            @media (min-width:769px){
                height:0;
                opacity:0;
                transition:margin-bottom 500ms 500ms,opacity 500ms;
            }
        }
    }
    
                a{
                    position:absolute;
                    bottom:0;
                    left:0;
                    background-color:#fff;
                    p{
                        color:#333;
                        font-weight:500;
                    }
                }
    @media (min-width:769px){
                a{
                    width:0rem;
                    overflow :hidden ;
                        transition: width 500ms;
                    p{
                        font-size:18rem;
                    }
                }
                &:hover{
                    img{
                        transform:scale(1.1) ;
                    }
                    .title{
                        p{
                            margin-bottom:210rem;
                            opacity:1 ;
                            transition:margin-bottom 500ms,opacity 500ms 500ms ;
                        }
                    }
                    a{
                        z-index:2;
                        width:440rem;
                        transition: width 500ms 500ms;
                    }
                }
            }
            
    @media (max-width:768px){
                    a{
                        z-index:2;
                        width:90%;
                    p{
                        font-size:14rem;
                    }
                    }
    }
`