import { cnstructionDelete, cnstructionLoad, cnstructionSave } from 'Service/perfomance'
import React, { useState } from 'react'
import { useMutation } from 'react-query'

const useConstruction = () => {
    const [state, setState] = useState([])
    const [isModal, setIsModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const { mutate: onLoad } = useMutation(cnstructionLoad, {
        onSuccess: ({ success, datas }) => {
            if (success) setState(datas)
        }
    })
    const { mutate: onSave } = useMutation(cnstructionSave, {
        onSuccess: ({ success, datas }) => {
            if (success) { setIsModal(false); onLoad(); }
        }
    })
    const { mutate: onDelete } = useMutation(cnstructionDelete, {
        onSuccess: ({ success, datas }) => {
            if (success) { setIsDelete(false); onLoad(); }
        }
    })

    return { state, isModal, onModal: setIsModal, isDelete, setIsDelete, onLoad, onSave, onDelete }
}

export default useConstruction