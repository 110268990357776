import React from "react";
import { Wrapper } from "./styled.Logo";

const Logo = ({ onClick, isColor = true, size = 160, mobileSize = 112 }) => {
    return (
        <Wrapper
            onClick={onClick}
            $size={size}
            $mobileSize={mobileSize}
            className="logo"
            viewBox="0 0 160 40"
            fill="none"
        >
            <path
                d="M55.3818 31.9688C53.702 31.25 52.4887 30.0313 51.7733 28.2813C51.12 26.7188 50.8089 24.5625 50.84 21.8438C50.84 19.1563 51.1822 17.0313 51.8977 15.4688C52.6443 13.8125 53.7953 12.6563 55.3818 12V15.125C54.6352 15.5625 54.1375 16.5313 53.8264 18.0313C53.6086 19.0625 53.5153 20.3438 53.5153 21.875C53.5153 23.4063 53.6086 24.6875 53.8264 25.7813C54.1375 27.375 54.6352 28.4063 55.3818 28.9063V31.9688Z"
                fill={isColor ? "black" : "#ccc"}
            />
            <path
                d="M64.6206 30.5V25.1563H57.6523V22.6875H74.3886V25.1563H67.3892V30.5H64.6206ZM67.7625 16C67.7936 16.75 68.3847 17.375 69.5668 17.8125C70.6556 18.2188 72.0555 18.4688 73.8286 18.5312V21.0312C72.1799 20.9688 70.7178 20.8125 69.4735 20.5C67.7625 20.0937 66.6115 19.5 66.0205 18.6875C65.4294 19.4688 64.2473 20.0625 62.5052 20.5C61.2609 20.7812 59.8299 20.9688 58.2123 21.0312V18.5625C59.9855 18.5 61.4164 18.2813 62.4741 17.875C63.6251 17.4375 64.2162 16.8125 64.2473 16.0312H58.2123V13.5312H73.7975V16H67.7625Z"
                fill={isColor ? "black" : "#ccc"}
            />
            <path
                d="M78.2146 25.8125C78.4323 24.7188 78.5257 23.4375 78.5257 21.9062C78.5257 20.375 78.4012 19.125 78.1835 18.0625C77.8724 16.5625 77.3747 15.5938 76.6592 15.1875V12.0625C78.2457 12.7188 79.3967 13.875 80.1122 15.5C80.8277 17.0625 81.1699 19.1875 81.1699 21.875C81.1699 24.625 80.8588 26.75 80.2366 28.3125C79.5211 30.0625 78.339 31.2812 76.6592 32V28.9375C77.3747 28.4375 77.9035 27.4062 78.2146 25.8125Z"
                fill={isColor ? "black" : "#ccc"}
            />
            <path
                d="M101.299 20.5312V23H84.5625V20.5312H101.299ZM94.4549 13.1875C94.4549 14.375 95.046 15.2812 96.2281 15.875C97.2547 16.375 98.779 16.6562 100.739 16.7187V19.1562C99.1211 19.1562 97.628 18.9375 96.2592 18.5312C94.6727 18.0625 93.5528 17.4375 92.9617 16.5937C92.3396 17.4375 91.2508 18.0625 89.6331 18.5312C88.2644 18.9375 86.7712 19.125 85.1536 19.125V16.7187C87.1134 16.6562 88.6066 16.375 89.6331 15.8438C90.8152 15.25 91.4063 14.375 91.4063 13.1875H94.4549ZM96.8814 22.4687C98.1879 23.4375 98.8412 24.6562 98.8412 26.0937C98.8412 27.625 98.219 28.9062 97.0058 29.9375C95.8548 30.9062 94.5171 31.375 92.9928 31.375C91.4063 31.375 90.0687 30.9062 88.8865 29.9375C87.6733 28.9062 87.0512 27.625 87.0512 26.0937C87.0512 24.6562 87.7044 23.4375 88.9799 22.4687C90.162 21.5625 91.4996 21.125 92.9928 21.125C94.3927 21.125 95.6992 21.5625 96.8814 22.4687ZM90.7841 23.9688C90.162 24.5312 89.8509 25.2187 89.8509 26.0625C89.8509 26.875 90.162 27.5625 90.7841 28.125C91.3752 28.6875 92.0907 28.9688 92.9306 28.9688C93.7394 28.9688 94.4549 28.6875 95.046 28.125C95.6681 27.5625 95.9792 26.875 95.9792 26.0625C95.9792 25.2187 95.6681 24.5312 95.046 23.9688C94.4549 23.4062 93.7394 23.125 92.9306 23.125C92.0907 23.125 91.3752 23.4062 90.7841 23.9688Z"
                fill={isColor ? "black" : "#ccc"}
            />
            <path
                d="M114.861 13.5312V16H111.346C111.47 17.0625 111.782 17.9062 112.279 18.5312C112.87 19.2812 113.773 19.7188 114.924 19.9063V22.5625C113.866 22.4688 112.87 22.1875 111.968 21.625C111.035 21.0625 110.319 20.3438 109.853 19.4375C109.355 20.3125 108.609 21.0625 107.582 21.625C106.649 22.1563 105.715 22.4375 104.782 22.5V19.875C105.964 19.6875 106.835 19.2188 107.426 18.5C107.893 17.875 108.204 17.0312 108.329 15.9687H104.876V13.5312H114.861ZM105.809 24.2188H108.577V27.875H120.616V30.3437H107.178C106.711 30.3437 106.369 30.25 106.182 30.0625C105.933 29.8125 105.84 29.4375 105.84 28.875V24.2188H105.809ZM117.692 13.5312H120.461V25.0938H117.692V13.5312Z"
                fill={isColor ? "black" : "#ccc"}
            />
            <path
                d="M134.272 13.5312V15.4375C134.272 19.6562 133.153 22.75 130.882 24.7187C129.264 26.125 127.18 26.875 124.629 27V24.2813C126.962 24.1875 128.704 23.4063 129.855 21.875C130.944 20.4688 131.473 18.5 131.473 15.9687H124.909V13.5312H134.272ZM137.445 13.5312H140.214V30.5937H137.445V13.5312Z"
                fill={isColor ? "black" : "#ccc"}
            />
            <path
                d="M151.973 14.0312C153.03 14.9062 153.559 16.0313 153.559 17.4063C153.559 18.7188 153.03 19.8437 151.973 20.7187C151.008 21.5625 149.888 21.9687 148.582 21.9687C147.275 21.9687 146.155 21.5625 145.16 20.7187C144.133 19.8125 143.604 18.7188 143.604 17.4063C143.604 16.0625 144.133 14.9375 145.16 14.0312C146.124 13.2187 147.275 12.8125 148.582 12.8125C149.888 12.8125 151.008 13.2187 151.973 14.0312ZM145.315 22.875H148.053V24.25H157.23V22.875H159.999V28.8437C159.999 29.4062 159.874 29.8125 159.625 30.0312C159.407 30.25 159.096 30.3437 158.63 30.3437H146.684C146.218 30.3437 145.875 30.25 145.689 30.0625C145.44 29.8125 145.347 29.4375 145.347 28.875V22.875H145.315ZM147.058 15.9062C146.622 16.3125 146.373 16.7812 146.373 17.375C146.373 17.9687 146.591 18.4375 147.058 18.8437C147.493 19.2187 147.991 19.4062 148.582 19.4062C149.142 19.4062 149.639 19.2187 150.075 18.8437C150.542 18.4375 150.759 17.9687 150.759 17.375C150.759 16.7812 150.542 16.2812 150.075 15.9062C149.639 15.5312 149.142 15.3437 148.582 15.3437C147.991 15.3437 147.493 15.5312 147.058 15.9062ZM157.23 26.5937H148.053V27.9062H157.23V26.5937ZM157.199 16.2187V13.5312H159.967V21.7188H157.199V18.6875H153.092V16.25H157.199V16.2187Z"
                fill={isColor ? "black" : "#ccc"}
            />
            <path
                d="M13.188 10.3666H22.5263C22.5263 10.3666 23.1404 4.95952 21.3161 3.14505C19.4737 1.33058 15.4819 -0.284297 11.3637 0.0423072C7.24548 0.368912 2.20608 0.695519 0.724965 4.97767C-0.738087 9.25982 0.219219 13.2154 1.77258 14.6669C3.32595 16.1185 6.55911 17.933 6.55911 17.933L11.454 20.7454C11.454 20.7454 13.5493 21.6526 13.6576 23.1949C13.766 24.7372 14.2718 27.3501 12.7726 28.0759C11.2734 28.8198 10.0632 28.221 9.8284 26.715C9.59359 25.209 9.8284 21.5075 9.8284 21.5075H0.363718V25.7896C0.363718 25.7896 0.255344 30.3984 4.89738 32.1221C9.53941 33.864 13.0074 33.3378 14.0911 33.2108C15.1749 33.0657 20.702 32.0496 22.1289 29.7089C23.5378 27.3864 23.2307 21.326 23.0139 20.8724C22.7791 20.4188 22.3637 17.6064 18.7151 15.5379C15.0665 13.4694 11.3818 11.5098 11.3818 11.5098C11.3818 11.5098 9.52135 11.0199 9.68391 8.75176C9.84647 6.48368 10.0632 5.1954 11.3818 5.32242C12.899 5.46757 13.4409 5.1954 13.188 10.3666Z"
                fill={isColor ? "#036EB8" : "#ccc"}
            />
            <path
                d="M26.229 8.78809H35.8563V33.7915C35.8563 33.7915 36.6149 40.1603 32.2799 39.997C27.9449 39.8518 19.6904 39.997 19.6904 39.997V35.2975H23.7183C23.7183 35.2975 26.1568 35.2431 26.229 33.5919C26.3193 31.8137 26.229 8.78809 26.229 8.78809Z"
                fill={isColor ? "#172A88" : "#ccc"}
            />
        </Wrapper>
    );
};

export default Logo;
