import styled, { css } from "styled-components";

export const Wrapper = styled.aside`
    ${({ $isActive, $zIndex, $timer = 300 }) => css`
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        position: fixed;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        transition: background-color ${$timer}ms,
            z-index 0s ${$isActive ? 0 : $timer}ms;
        background-color: rgba(0, 0, 0, ${$isActive ? 0.4 : 0});
        z-index: ${$isActive ? $zIndex : -10};
    `}
`;
