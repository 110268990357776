import { InputWrapper } from "Pages/Customer/styled.Customer";
import React from "react";

const Input = ({ label, ...props }) => {
    return (
        <InputWrapper>
            <p>{label}</p>
            <input type="text" {...props} />
        </InputWrapper>
    );
};

export default Input;
