import { COMPANYS, COMPANYS_CEO } from "Pages/Companys/Route.Companys";
import { CUSTOMER, CUSTOMER_INQUIRY } from "Pages/Customer/Route.Customer";
import { INTRODUCTION, INTRODUCTION_BUSINESS } from "Pages/Introduction/Route.Introduction";
import { PERFORMANCE, PERFORMANCE_CONSTRUCTION } from "Pages/Performance/Route.Performance";

export const LinkData = [
    {
        to: COMPANYS_CEO,
        target: COMPANYS,
        title: "회사 소개",
        sub: 'Company'
    },
    {
        to: INTRODUCTION_BUSINESS,
        target: INTRODUCTION,
        title: "사업 소개",
        sub: 'Business Introduction'
    },
    {
        to: PERFORMANCE_CONSTRUCTION,
        target: PERFORMANCE,
        title: "공사 실적",
        sub: 'Construction Performance'
    },
    {
        to: CUSTOMER_INQUIRY,
        target: CUSTOMER,
        title: "고객 센터",
        sub: 'Customer Service'
    },
]