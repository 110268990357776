import React from "react";
import { MainLaut, MainLayout, Scroll } from "./styled.Main";
import useFullPage from "../../Hooks/useFullPage";
import Footer from "../Footer/Footer";
import MainCompanys from "./Pages/Companys/Main.Companys";
import MainInquiry from "./Pages/Inquiry/Main.Inquiry";
import MainBanner from "./Pages/Banner/Main.Banner";

const Main = () => {
    const timer = 1000;
    const elements = [
        MainBanner,
        MainCompanys,
        // MainPerformance,
        MainInquiry,
        Footer,
    ];
    const maxContent = elements?.length;
    const { ref, idx, onTop } = useFullPage(maxContent, timer);
    return (
        <MainLayout className="pc">
            <Scroll
                $isLast={idx === maxContent - 1}
                $timer={timer}
                $idx={Math.min(idx, maxContent - 2)}
                ref={ref}
            >
                {elements?.map((Element, key) => (
                    <Element key={key} onTop={onTop} />
                ))}
            </Scroll>
        </MainLayout>
    );
};

export default Main;
