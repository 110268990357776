import { css, styled } from "styled-components";

export const Wrapper = styled.svg`
${({ $size, $mobileSize, onClick }) => css`
    width:${$size}rem;
    
      @media (max-width:768px){
    width:${$mobileSize}rem;
        
      }
    ${onClick ? css`
    cursor:pointer;`: ''}
`}
`