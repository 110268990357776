import { Flex } from "Common/UI/Atoms";
import React, { useEffect, useState } from "react";
import {
    SubWrapper,
    TitleInput,
    ViewWrppaer,
} from "../../styled.Performance.Photograph";
import useInput from "Hooks/useInput";
import Upload from "./Components/Upload";
import ImageInput from "./Components/ImageInput";
import { useMutation } from "react-query";
import { photographDelete, photographSave } from "Service/perfomance";
import moment from "moment";
import usePhotograph from "../../usePhotograph";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Modal } from "Common/UI/Molecules";

const PerformancePhotographDetail = ({ write }) => {
    const defaultDate = {
        idx: 0,
        title: "",
        company: "",
        date: "",
        files: [],
    };
    const { state, onChange } = useInput(defaultDate);
    const { id } = useParams();
    const [cookies] = useCookies();
    const isAdmin = !!cookies?.username;
    const [isDelete, setIsDelete] = useState(false);

    const { onView } = usePhotograph();

    useEffect(() => {
        if (id) onView(id, onChange);
    }, [id]);

    const fileSet = (e, n, isDelete) => {
        if (n === undefined) {
            onChange({ files: [...state.files, e] });
        } else {
            onChange({
                files: isDelete
                    ? state.files?.filter((_, key) => key !== n)
                    : state.files?.map((i, key) =>
                          key === n
                              ? {
                                    comment: "",
                                    ...i,
                                    [e.target.name]: e.target.value,
                                }
                              : i
                      ),
            });
        }
    };
    const navigate = useNavigate();
    const { mutate: save } = useMutation(photographSave, {
        onSuccess: ({ success, saveID }) => {
            navigate("/performance/photograph/" + saveID);
        },
    });
    const { mutate: onDelete } = useMutation(photographDelete, {
        onSuccess: ({ success, saveID }) => {
            navigate("/performance/photograph/");
        },
    });

    const onSave = () => {
        save(state);
    };

    return (
        <>
            <ViewWrppaer column>
                <TitleInput
                    name={"title"}
                    type={"text"}
                    as={write ? undefined : "h2"}
                    onChange={onChange}
                    value={state.title}
                    children={write ? undefined : state.title}
                    placeholder="제목을 입력하세요"
                />
                <SubWrapper align={"center"} gap={16}>
                    {write ? (
                        <input
                            name={"company"}
                            type={"text"}
                            onChange={onChange}
                            value={state.company}
                            placeholder="업체명을 입력하세요"
                        />
                    ) : (
                        <p>{state.company}</p>
                    )}
                    <span />
                    {write ? (
                        <input
                            value={state.date}
                            name={"date"}
                            onChange={onChange}
                            type={"month"}
                        />
                    ) : (
                        <p>
                            {moment(state.date, "YYYY-MM").format(
                                "YYYY년 MM월"
                            )}
                        </p>
                    )}
                </SubWrapper>
                <Flex column gap={16} margin={[0, 0, 120]}>
                    {write ? <Upload onChange={fileSet} /> : undefined}
                    {state?.files?.map((i, key) => (
                        <ImageInput
                            state={i}
                            isEdit={write}
                            key={key}
                            onChange={(e) => fileSet(e, key)}
                            onDelete={(e) => fileSet(e, key, true)}
                        />
                    ))}
                    {isAdmin ? (
                        <Flex margin={[60, 0, 0]} align={"right"}>
                            {write ? (
                                <button onClick={onSave}>등록하기</button>
                            ) : (
                                <button onClick={() => setIsDelete(true)}>
                                    삭제하기
                                </button>
                            )}
                        </Flex>
                    ) : undefined}
                </Flex>
            </ViewWrppaer>
            <Modal
                padding={[20, 40]}
                isActive={isDelete}
                successText={"삭제"}
                onClose={setIsDelete}
                onSuccess={() => onDelete({ idx: Number(id) })}
            >
                현재 게시물을 삭제하겠습니까?
            </Modal>
        </>
    );
};

export default PerformancePhotographDetail;
