import { Flex, Icon } from "Common/UI/Atoms";
import React, { useState } from "react";
import Input from "./Input";
import Textarea from "./Textarea";
import { ButtonWrapper, ListWrapper } from "Pages/Customer/styled.Customer";
import { useMutation } from "react-query";
import { mail } from "Service/common";

const InquiryList = () => {
    const defaultData = {
        name: "",
        phone: "",
        title: "",
        content: "",
    };
    const [state, setState] = useState(defaultData);
    const { mutate } = useMutation(mail, {
        onSuccess: ({ success, message }) => {
            if (success) {
                alert(message);
                setState(defaultData);
            } else alert("오류가 발생했습니다");
        },
    });

    const onSubmit = () => {
        if (!state?.name || !state.phone || !state?.title || !state?.content) {
            return alert("작성하지 않은 항목이 있습니다");
        } else if (state?.phone?.length < 10) {
            return alert("핸드폰번호를 확인해주세요");
        } else if (state?.content?.length < 10) {
            return alert("문의내용을 상세하게 작성해주세요");
        } else {
            mutate({ ...state });
        }
    };

    return (
        <ListWrapper
            gap={120}
            margin={[0, 0, 120]}
            width={"100%"}
            align={"center"}
            column
        >
            <Flex className="list" width={1200} column gap={40}>
                <Flex className="user" gap={20}>
                    <Input
                        onChange={({ target: { name, value } }) =>
                            setState({
                                ...state,
                                [name]: value,
                            })
                        }
                        name={"name"}
                        placeholder={"이름을 입력하세요"}
                        value={state?.name}
                        label={"이름"}
                    />
                    <Input
                        onChange={({ target: { name, value } }) =>
                            setState({
                                ...state,
                                [name]: value?.replace(/[^0-9]/g, ""),
                            })
                        }
                        maxlength={11}
                        name={"phone"}
                        type={"tel"}
                        placeholder={"핸드폰 번호를 입력하세요"}
                        value={state?.phone}
                        label={"핸드폰 번호"}
                    />
                </Flex>
                <Input
                    onChange={({ target: { name, value } }) =>
                        setState({ ...state, [name]: value })
                    }
                    name={"title"}
                    placeholder={"제목을 입력하세요"}
                    value={state?.title}
                    label={"문의 제목"}
                />
                <Textarea
                    onChange={({ target: { name, value } }) =>
                        setState({ ...state, [name]: value })
                    }
                    name={"content"}
                    placeholder={"문의 내용을 입력하세요"}
                    value={state?.content}
                    label={"문의 내용"}
                />
            </Flex>
            <ButtonWrapper onClick={onSubmit}>
                <Icon.Sns />
                문의하기
            </ButtonWrapper>
        </ListWrapper>
    );
};

export default InquiryList;
