import React, { useRef } from "react";
import { Wrapper } from "./styled.Overlay";

/**
 * 배경 Gray 전환
 * @param {{
 * isActive:boolean
 * zIndex:number
 * timer:number
 * onClose:(boolean:false) => void
 * children:JSX.Element
 * }} param0
 * @returns
 */
const Overlay = ({
    isActive,
    zIndex = 20,
    children,
    timer = 300, //이벤트 속도 {timer}ms
    onClose,
}) => {
    return (
        <Wrapper
            onClick={onClose && isActive ? () => onClose(false) : undefined}
            $timer={timer}
            $isActive={isActive}
            $zIndex={zIndex}
        >
            {children}
        </Wrapper>
    );
};

export default Overlay;
