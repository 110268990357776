import React from "react";
import { TableWrapper, TdWrapper } from "../styled.Performance.Construction";
import moment from "moment";

const ConstructionTable = ({ datas, isAdmin, onDelete }) => {
    return (
        <TableWrapper>
            {datas?.map((i, key) => (
                <TdWrapper $isAdmin={isAdmin} key={key}>
                    <p>{i?.company}</p>
                    <h3>{i?.title}</h3>
                    <p>{moment(i?.date, "YYYY-MM").format("YYYY.MM")}</p>
                    {isAdmin ? (
                        <button onClick={() => onDelete(i)}>삭제</button>
                    ) : undefined}
                </TdWrapper>
            ))}
        </TableWrapper>
    );
};

export default ConstructionTable;
