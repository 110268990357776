import { Flex, Grid } from "Common/UI/Atoms";
import { Content } from "Common/UI/Molecules";
import React from "react";
import PhotographBody from "./Components/Photograph.Body";
import { Link, Route, Routes } from "react-router-dom";
import { AdminWrapper } from "./styled.Performance.Photograph";
import PerformancePhotographDetail from "./Pages/Detail/Performance.Photograph.Detail";
import PerformancePhotographList from "./Pages/List/Performance.Photograph.List";

const PerformancePhotograph = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<PerformancePhotographList />} />
                <Route
                    path="/write"
                    element={<PerformancePhotographDetail write />}
                />
                <Route path="/:id" element={<PerformancePhotographDetail />} />
            </Routes>
        </>
    );
};

export default PerformancePhotograph;
