import { styled } from "styled-components";

export const Wrapper = styled.div`
    width:100vw;
    height:400rem;
    
    @media (max-width:768px){
        height:160rem;
    }
    position:relative ;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    z-index:1;
    img{
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        object-fit:cover;
        z-index:-1;
    }
    color:#fff;
    h2{
        font-size:28rem;
        font-weight:500;
        margin-bottom:20rem;
        @media (max-width:768px){
            font-size:14rem;
            margin-bottom:8rem;
        }
    }
    h3{
        font-size:50rem;
        font-weight:600;
        @media (max-width:768px){
            font-size:20rem;
        }
    }
`