import React from "react";
import { useMutation } from "react-query";
import { fileUpload } from "../../Service/common";
import { login } from "../../Service/account";
import moment from "moment/moment";

const Test = () => {
    const { mutate } = useMutation(fileUpload);
    const onChange = (e) => {
        if (!e.target.files?.length) return;
        const config = {
            file: e.target.files[0],
            date: moment().format("YYYYMMDD"),
        };
        mutate(config);
    };

    const { mutate: onLogin } = useMutation(login);

    return (
        <div>
            <input name="file" type="file" onChange={onChange} />
            <button onClick={() => onLogin()}>로그인</button>
        </div>
        
    );
};

export default Test;
