import React from "react";
import { ListWrapper } from "../styled.Main.Inquiry";
import { Flex } from "Common/UI/Atoms";

const InquiryList = ({ icon, title, info }) => {
    return (
        <ListWrapper>
            <Flex className="titleBox" gap={12}>
                {icon}
                {title}
            </Flex>
            <Flex className="contentBox" fill>
                {info}
            </Flex>
        </ListWrapper>
    );
};

export default InquiryList;
