import { Banner, Flex } from "Common/UI/Atoms";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CustomerData } from "./Data.Customer";
import { Tab } from "Common/UI/Molecules";
import CustomerRouter from "./Customer.Router";

const Customer = () => {
    const location = useLocation();
    const [state, setState] = useState({
        title: "",
        sub: "",
    });

    useEffect(() => {
        const target = CustomerData?.find(
            ({ path }) => location.pathname === path
        );
        setState({
            title: target?.title,
            sub: target?.sub,
        });
    }, [location]);

    return (
        <>
            <Banner {...state} />
            <Tab
                list={CustomerData?.map(({ path, title }) => ({
                    to: path,
                    title,
                }))}
            />
            <Flex align={"center"} column>
                <CustomerRouter />
            </Flex>
        </>
    );
};

export default Customer;
