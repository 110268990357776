import { css, styled } from "styled-components";

export const Svg = styled.svg`
    ${({ $width, $moSize, $style }) => css`
        width: ${$width}rem;

        ${$moSize ? css`
        @media (max-width:768px){
        width: ${$moSize}rem;
        }
        `: ''}
        ${$style || ""}
    `}
`;
