import { InputWrapper } from "Pages/Customer/styled.Customer";
import React from "react";

const Textarea = ({ label, ...props }) => {
    return (
        <InputWrapper>
            <p>{label}</p>
            <textarea type="text" {...props} />
        </InputWrapper>
    );
};

export default Textarea;
