import { Grid } from "Common/UI/Atoms";
import { Content } from "Common/UI/Molecules";
import React from "react";
import InfoList from "./Components/InfoList";
import { GridWrapper } from "Pages/Introduction/styled.Introduction";

const IntroductionInfo = () => {
    return (
        <>
            <Content
                title={"사업 소개"}
                info={
                    <>
                        <span className="pc">
                            승진 기업은 석유화학 플랜트, 정제 플랜트, 산업
                            플랜트 사업 분야에서 <br />
                            <b>“다양한 경험과 우수한 기술력”</b>을 보유하고
                            있습니다
                        </span>
                        <span className="mo">
                            승진 기업은 석유화학 플랜트, <br />
                            정제 플랜트, 산업 플랜트 사업 분야에서
                            <br />
                            <b>“다양한 경험과 우수한 기술력”</b>을 보유하고
                            있습니다
                        </span>
                    </>
                }
            />
            <GridWrapper>
                <InfoList
                    title={"석유화학 플랜트"}
                    sub={"Petrochemical Plant"}
                    src={"/images/business1.png"}
                />
                <InfoList
                    title={"정제 플랜트"}
                    sub={"Refinery Plant"}
                    valign={"bottom"}
                    src={"/images/business2.png"}
                />
                <InfoList
                    title={"산업 플랜트"}
                    sub={"Industrial Plant"}
                    src={"/images/business3.png"}
                />
            </GridWrapper>
        </>
    );
};

export default IntroductionInfo;
