import { Flex, Grid } from "Common/UI/Atoms";
import { css, styled } from "styled-components";

export const TableWrapper = styled(Flex).attrs({
    column: true,
    margin: [0, 0, 120],
})`
                border-top:2rem solid #333333;
    @media (max-width:768px){
        margin: 0 16rem 40rem;
        width:calc(100% - 32rem);
    }
`

export const TdWrapper = styled(Grid).attrs({
    valign: 'middle'
})`${({ $isAdmin }) => css`
        border-bottom:1rem solid #333333;
        @media (min-width:769px){
            gap:40rem;
            height: 80rem;
            padding: 0 32rem;
            width: 1200rem;
            grid-template-columns: 160rem 1fr auto${$isAdmin ? ' auto' : ''}; 
        }
        @media (max-width:768px){
            padding: 20rem 8rem;
            flex-direction:column;
        }
        p{
            font-size:16rem;
            color:#666;
            @media (max-width:768px){
                font-size:14rem;
            }

        }
        h3{
            font-size:20rem;
            color:#333;
            @media (max-width:768px){
                font-size:16rem;
            }
        }
    `}
`