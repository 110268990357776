import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Empty = ({ url = "/" }) => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(url);
    }, []);
    return undefined;
};

export default Empty;
