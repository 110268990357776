import React from "react";
import { Flex, Grid } from "Common/UI/Atoms";
import DetailView from "./Components/DetailView";
import { CompanyWrapper } from "./styled.Main.Companys";
import {
    COMPANYS_CEO,
    COMPANYS_ORGANIZATION,
    COMPANYS_OUTLINE,
    COMPANYS_POLICY,
} from "Pages/Companys/Route.Companys";
import { useSelector } from "react-redux";

const MainCompanys = () => {
    return (
        <CompanyWrapper>
            <Flex className="content" column>
                <Flex fill column className="contentHeader">
                    <h5>회사 소개</h5>
                    <Flex height={67}>
                        <h2>
                            <span>Our</span> Company
                        </h2>
                    </Flex>
                </Flex>
                <Flex className="contentScroll" width={"100%"}>
                    <Grid height={460} column={4} className="contentBody">
                        <DetailView
                            to={COMPANYS_CEO}
                            title={"대표 인사말"}
                            subTitle={"Representative Reeting"}
                            info={
                                "최고의 품질과 완벽한 기술력으로 고객이 인정하는\n믿음의 기업으로 도약하기 위해 노력합니다"
                            }
                            img={"/images/reeting.png"}
                        />
                        <DetailView
                            to={COMPANYS_OUTLINE}
                            title={"회사 개요"}
                            subTitle={"Company Overview"}
                            info={
                                "회사 소재지, 대표자, 전화번호 및 팩스번호 등\n회사 개요 정보를 안내합니다"
                            }
                            img={"/images/overview.png"}
                        />
                        <DetailView
                            to={COMPANYS_ORGANIZATION}
                            title={"조직도"}
                            subTitle={"Organization Chart"}
                            info={
                                "영구 지속 가능한 경영 체계 구축의 시작,\n각 분야별 최고 전문가들로 구성된 조직입니다"
                            }
                            img={"/images/chart.png"}
                        />
                        <DetailView
                            to={COMPANYS_POLICY}
                            title={"경영방침"}
                            subTitle={"Management Policy"}
                            info={
                                "플랜트 공사의 경쟁력 강화와\n영구 지속 가능한 경영 체제를 구축합니다"
                            }
                            img={"/images/policy.png"}
                        />
                    </Grid>
                </Flex>
            </Flex>
        </CompanyWrapper>
    );
};

export default MainCompanys;
