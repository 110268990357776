import React from "react";
import { NaviWrapper } from "../styled.Header";
import { LinkData } from "../Data.Header";
import { useNavigate } from "react-router-dom";
import { Flex } from "Common/UI/Atoms";
import { Svg } from "Common/UI/Atoms/Icon/styled.Icon";

const Navi = ({ isActive, onClose }) => {
    const navigate = useNavigate();
    return (
        <NaviWrapper $isActive={isActive}>
            <Flex align={"right"} className="headNav">
                <button onClick={onClose}>
                    <Svg $width={24} viewBox="0 0 24 24" fill="none">
                        <path
                            d="M2.4007 22.8132L1.1875 21.6L10.7875 12L1.1875 2.4L2.4007 1.1868L12.0007 10.7868L21.6007 1.1868L22.8139 2.4L13.2139 12L22.8139 21.6L21.6007 22.8132L12.0007 13.2132L2.4007 22.8132Z"
                            fill="#333333"
                        />
                    </Svg>
                </button>
            </Flex>
            <Flex column gap={12}>
                {LinkData?.map((i, key) => (
                    <button
                        onClick={() => {
                            navigate(i.to);
                            onClose();
                        }}
                        key={key}
                    >
                        <h4>{i.title}</h4>
                        <p>{i.sub}</p>
                    </button>
                ))}
            </Flex>
        </NaviWrapper>
    );
};

export default Navi;
