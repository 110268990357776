import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    display: {
        pc: window.outerWidth > 768,
        mobile: window.outerWidth <= 768,
    },
};

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        onDisplaySetting: (state, { payload }) => {
            state.display = {
                ...payload,
            };
        },
    },
});

export const { onDisplaySetting } = commonSlice.actions;

export default commonSlice;
