import { Flex } from "Common/UI/Atoms";
import React, { useEffect, useRef } from "react";
import { MapWrapper } from "../styled.Companys.Map";

const { kakao } = window;

const Map = ({ width = 1200, height = 600 }) => {
    const ref = useRef();
    useEffect(() => {
        const container = ref.current; //지도를 담을 영역의 DOM 레퍼런스
        const position = new kakao.maps.LatLng(
            35.955232583001674,
            126.67632517227081
        );
        const options = {
            //지도를 생성할 때 필요한 기본 옵션
            center: position, //지도의 중심좌표.
            level: 1, //지도의 레벨(확대, 축소 정도)
        };

        const map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴
        map.setDraggable(false);
        map.setZoomable(false);

        const content =
            '<div class="customoverlay">' +
            '  <a href="http://kko.to/OfUYu4Cj04" target="_blank">' +
            '    <img src="/images/seungjin.svg" />' +
            "  </a>" +
            "</div>";

        new kakao.maps.CustomOverlay({
            map: map,
            position: position,
            content: content,
            yAnchor: 1,
        });
    }, []);

    return (
        <MapWrapper className="map" width={width} height={height} ref={ref} />
    );
};

export default Map;
