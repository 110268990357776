import React from "react";
import { Route, Routes } from "react-router-dom";
import Empty from "Pages/Empty/Empty";
import { PerformanceData } from "./Data.Performance";
import { PERFORMANCE, PERFORMANCE_CONSTRUCTION } from "./Route.Performance";

const PerformanceRouter = () => {
    const replaceUrl = (url) => url.replace(PERFORMANCE, "");

    return (
        <Routes>
            <Route
                path={"*"}
                element={<Empty url={PERFORMANCE_CONSTRUCTION} />}
            />
            {PerformanceData?.map(({ path, Element }, key) => (
                <Route
                    path={replaceUrl(path) + "*"}
                    element={<Element />}
                    key={key}
                />
            ))}
        </Routes>
    );
};

export default PerformanceRouter;
