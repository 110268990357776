import { Content, Modal } from "Common/UI/Molecules";
import React, { useEffect, useState } from "react";
import useConstruction from "./useConstruction";
import ConstructionTable from "./Components/Construction.Table";
import { AdminWrapper } from "../Photograph/styled.Performance.Photograph";
import { useCookies } from "react-cookie";
import ModalConstAdd from "./Components/Modal.ConstAdd";

const PerformanceConstruction = () => {
    const {
        state,
        isModal,
        onModal,
        onSave,
        onLoad,
        isDelete,
        setIsDelete,
        onDelete,
    } = useConstruction();
    const [cookies] = useCookies();
    const isAdmin = !!cookies?.username;
    useEffect(() => {
        onLoad();
    }, []);
    return (
        <>
            <Content title={"주요 공사 실적"} />

            {isAdmin ? (
                <AdminWrapper align={"right"} width={1200}>
                    <button onClick={() => onModal(true)}>실적 추가</button>
                </AdminWrapper>
            ) : undefined}
            <ConstructionTable
                isAdmin={isAdmin}
                datas={state}
                onDelete={setIsDelete}
            />
            <ModalConstAdd
                isActive={isModal}
                onClose={onModal}
                onSuccess={onSave}
            />
            <Modal
                padding={[20, 40]}
                isActive={!!isDelete}
                successText={"삭제"}
                onClose={setIsDelete}
                onSuccess={() => onDelete({ idx: isDelete?.idx })}
            >
                선택한 {isDelete?.company} 실적을 삭제하겠습니까?
            </Modal>
        </>
    );
};

export default PerformanceConstruction;
