import { Grid } from "Common/UI/Atoms";
import { Content } from "Common/UI/Molecules";
import React from "react";
import PerformList from "./Components/PerformList";
import { PerformGridWrapper } from "Pages/Introduction/styled.Introduction";

const IntroductionPerform = () => {
    return (
        <>
            <Content
                title={"공사 수행"}
                info={
                    <>
                        <span className="pc">
                            {
                                "승진 기업은 플랜트, 발전소, 조선소, 2차전지 Coater, RTO 에서"
                            }
                            <br /> {"보온·보냉공사 및 자재 납품을 "}
                            <b>신뢰와 믿음으로 수행하고 있습니다</b>
                        </span>
                        <span className="mo">
                            {
                                "승진 기업은 플랜트, 발전소, 조선소, 2차전지 Coater, RTO 에서 보온·보냉공사 및 자재 납품을 "
                            }
                            <b>신뢰와 믿음으로 수행하고 있습니다</b>
                        </span>
                    </>
                }
            />
            <PerformGridWrapper>
                <PerformList
                    title={"플랜트 보온공사 및 자재 납품"}
                    sub={"Plant thermal insulation work and material delivery"}
                    src={"/images/construction1.png"}
                />
                <PerformList
                    title={"발전소 보온공사 및 자재 납품"}
                    sub={
                        "Power plant thermal insulation work and material delivery"
                    }
                    reverse
                    src={"/images/construction2.png"}
                />
                <PerformList
                    title={"조선소 보온공사 및 자재 납품"}
                    sub={"Shipyard Insulation Work and material delivery"}
                    src={"/images/construction3.png"}
                />
                <PerformList
                    title={"2차전지 Coater 보온공사 및 납품"}
                    sub={"Thermal insulation work and \nCoater insulation work"}
                    reverse
                    src={"/images/construction4.png"}
                />
                <PerformList
                    title={"RTO 보온공사 및 납품"}
                    sub={"RTO thermal insulation work and delivery"}
                    src={"/images/construction5.png"}
                />
            </PerformGridWrapper>
        </>
    );
};

export default IntroductionPerform;
