import { Flex, Icon } from "Common/UI/Atoms";
import { Content } from "Common/UI/Molecules";
import React from "react";
import OutlineList from "./Components/OutlineList";
import { Wrapper } from "./styled.Companys.Outline";

const CompanysOutline = () => {
    return (
        <>
            <Content
                title={"회사 개요"}
                info={
                    <>
                        전북 군산시에 위치한 주식회사 승진 기업은
                        <br />
                        2023년 1월 설립된{" "}
                        <b>”플랜트 보온 공사 분야 최고 기업”</b> 입니다
                    </>
                }
            />
            <Wrapper column gap={8}>
                {[
                    {
                        icon: <Icon.Company />,
                        title: "상호",
                        info: "(주)승진기업",
                    },
                    {
                        icon: <Icon.Address />,
                        title: "소재지",
                        info: "(54149) 전라북도 군산시 미성로 503, 102호 (미룡동)",
                    },
                    {
                        icon: <Icon.User />,
                        title: "대표자",
                        info: "강기식",
                    },
                    {
                        icon: <Icon.Tel />,
                        title: "회사 전화",
                        info: "063-471-7403",
                    },
                    {
                        icon: <Icon.Tel />,
                        title: "대표 전화",
                        info: "010-8776-7403",
                    },
                    {
                        icon: <Icon.Fax />,
                        title: "팩스번호",
                        info: "063-471-7404",
                    },
                    {
                        icon: <Icon.Truck />,
                        title: "사업의 종류",
                        info: "플랜트 보온 공사",
                    },
                    {
                        icon: <Icon.Outer />,
                        title: "설립일",
                        info: "2023년 1월",
                    },
                ]?.map((props, key) => (
                    <OutlineList key={key} {...props} />
                ))}
            </Wrapper>
        </>
    );
};

export default CompanysOutline;
