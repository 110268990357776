import React, { forwardRef } from "react";
import { Wrapper } from "./styled.Grid";

const Grid = forwardRef(
    (
        {
            children,
            className,
            column,
            row,
            padding,
            margin,
            width,
            height,
            fill,
            gap,
            align,
            valign,
            ...props
        },
        ref
    ) => {
        return (
            <Wrapper
                $width={width}
                $height={height}
                $padding={padding}
                $margin={margin}
                $flex={fill}
                $column={column}
                $row={row}
                $gap={gap}
                $align={align}
                $valign={valign}
                ref={ref}
                className={"grid " + (className || "")}
                {...props}
            >
                {children}
            </Wrapper>
        );
    }
);

export default Grid;
