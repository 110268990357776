import PerformanceConstruction from "./Pages/Construction/Performance.Construction";
import PerformancePhotograph from "./Pages/Photograph/Performance.Photograph";
import { PERFORMANCE_CONSTRUCTION, PERFORMANCE_PHOTOGRAPH } from "./Route.Performance";


export const PerformanceData = [
    {
        title: "주요 공사 실적",
        sub: 'Major Construction Performance',
        path: PERFORMANCE_CONSTRUCTION,
        Element: PerformanceConstruction,
    },
    { title: "시공 사진", sub: 'Construction Photograph', path: PERFORMANCE_PHOTOGRAPH, Element: PerformancePhotograph },
];