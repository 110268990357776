import { Content } from "Common/UI/Molecules";
import React from "react";
import InquiryList from "./Components/InquiryList";

const CustomerInquiry = () => {
    return (
        <>
            <Content title={"문의사항"} />
            <InquiryList />
        </>
    );
};

export default CustomerInquiry;
