import { Flex } from "Common/UI/Atoms";
import React from "react";
import { DetailWrapper } from "../styled.Main.Companys";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Svg } from "Common/UI/Atoms/Icon/styled.Icon";

const DetailView = ({ title, subTitle, info, img, to }) => {
    const { display } = useSelector(({ common }) => common);
    return (
        <DetailWrapper>
            <img src={img} alt="temp" />
            <Flex
                className="title"
                padding={[0, 0, 0, 60]}
                width={"100%"}
                height={"100%"}
                column
            >
                <h6>{title}</h6>
                <Flex margin={[0, 0, 32]} height={38}>
                    <h3>{subTitle}</h3>
                </Flex>
                <p className="pc">{info}</p>
            </Flex>
            <Link to={to}>
                <Flex
                    padding={[0, display?.mobile ? 12 : 60]}
                    height={display?.mobile ? 40 : 68}
                    width={display?.mobile ? "100%" : 440}
                    align={"side"}
                >
                    <p>Detail View</p>
                    <Svg
                        $width={display?.mobile ? 30 : 51}
                        viewBox="0 0 51 12"
                        fill="none"
                    >
                        <path d="M0 11H50L42.9167 1" stroke="#333333" />
                    </Svg>
                </Flex>
            </Link>
        </DetailWrapper>
    );
};

export default DetailView;
