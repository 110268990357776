import { styled } from "styled-components";

export const Wrapper = styled.button`
position:fixed ;
display:flex ;
align-items:center;
justify-content:center;
right:240rem;
bottom:240rem;
background-color:#fff;
width:60rem ;
height:60rem;
border-radius:100%;
svg{
    width:24rem;
}
`