import React from "react";
import { LinkWrapper, TopButtonWrapper, TopWrapper } from "../styled.Footer";
import { Flex, Icon, Logo } from "../../../Common/UI/Atoms";
import { CUSTOMER_INQUIRY } from "Pages/Customer/Route.Customer";
import { COMPANYS_MAP } from "Pages/Companys/Route.Companys";
import { useSelector } from "react-redux";

const FooterTop = ({ onTop }) => {
    const { display } = useSelector(({ common }) => common);
    return (
        <TopWrapper>
            <Flex column valign="side">
                <Logo isColor={false} size={145} />
                <Flex column gap={display?.mobile ? 8 : 0}>
                    <Flex gap={display?.mobile ? 12 : 40}>
                        <h5>사업자등록번호</h5>
                        <p>892-88-02797</p>
                    </Flex>
                    <Flex gap={display?.mobile ? 12 : 40}>
                        <h5>주소</h5>
                        <p>전라북도 군산시 미성로 503, 102호 (미룡동)</p>
                    </Flex>
                    <Flex gap={display?.mobile ? 12 : 40}>
                        <h5>회사 전화</h5>
                        <p>063-471-7403</p>
                    </Flex>
                    <Flex gap={display?.mobile ? 12 : 40}>
                        <h5>대표 전화</h5>
                        <p>010-8776-7403</p>
                    </Flex>
                    <Flex gap={display?.mobile ? 12 : 40}>
                        <h5>팩스</h5>
                        <p>063-471-7404</p>
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                align={display?.mobile ? undefined : "right"}
                valign={"side"}
                column
            >
                {display?.mobile ? undefined : (
                    <TopButtonWrapper onClick={onTop}>
                        <svg viewBox="0 0 24 24" fill="none">
                            <path
                                d="M10.5 24V5.7375L2.1 14.1375L0 12L12 0L24 12L21.9 14.1375L13.5 5.7375V24H10.5Z"
                                fill="#333333"
                            />
                        </svg>
                    </TopButtonWrapper>
                )}
                <Flex gap={8} column={display?.mobile}>
                    <LinkWrapper to={CUSTOMER_INQUIRY}>
                        <Icon.Sns fill />
                        <p>
                            <b>문의 사항</b>이 있으신가요?
                        </p>
                    </LinkWrapper>
                    <LinkWrapper to={COMPANYS_MAP}>
                        <Icon.Address fill />
                        <p>
                            <b>오시는 길</b>을 안내할게요!
                        </p>
                    </LinkWrapper>
                </Flex>
            </Flex>
        </TopWrapper>
    );
};

export default FooterTop;
