import { Flex, Input } from "Common/UI/Atoms";
import { Modal } from "Common/UI/Molecules";
import useInput from "Hooks/useInput";
import React from "react";

const ModalConstAdd = ({ isActive, onClose, onSuccess }) => {
    const defaultData = {
        company: "",
        title: "",
        date: "",
    };

    const { state, onChange } = useInput(defaultData);

    const isDesabled = !state?.company || !state?.title || !state?.date;

    return (
        <Modal
            padding={[20, 40]}
            isActive={isActive}
            onClose={onClose}
            onSuccess={isDesabled ? undefined : () => onSuccess(state)}
        >
            <Flex width={"100%"} column gap={16}>
                <Input
                    value={state?.company}
                    onChange={onChange}
                    name={"company"}
                    label={"사업장명"}
                />
                <Input
                    value={state?.title}
                    onChange={onChange}
                    name={"title"}
                    label={"실적명"}
                />
                <Input
                    value={state?.date}
                    onChange={onChange}
                    name={"date"}
                    type={"month"}
                    label={"공사 일시"}
                />
            </Flex>
        </Modal>
    );
};

export default ModalConstAdd;
