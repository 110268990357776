import React from "react";
import { ButtonWrapper, Wrapper } from "./styled.Tab";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex } from "Common/UI/Atoms";

const Tab = ({ list }) => {
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <Wrapper>
            <Flex>
                {list?.map(({ title, to }, key) => {
                    const isActive = location.pathname.includes(to);

                    return (
                        <ButtonWrapper
                            onClick={() => navigate(to)}
                            $isActive={isActive}
                            key={key}
                        >
                            {title}
                        </ButtonWrapper>
                    );
                })}
            </Flex>
        </Wrapper>
    );
};

export default Tab;
