import { Content } from "Common/UI/Molecules";
import React from "react";
import Map from "./Components/Map";
import { Flex, Logo } from "Common/UI/Atoms";
import { Wrapper } from "./styled.Companys.Map";

const CompanysMap = () => {
    return (
        <>
            <Content
                title={"오시는 길"}
                info={
                    <>{"(54149) 전라북도 군산시 미성로 503, 102호 (미룡동)"}</>
                }
            />
            <Wrapper gap={20}>
                <Map />
                <Flex width={1200} padding={[0, 60]} align={"side"}>
                    <Logo size={160} />
                </Flex>
            </Wrapper>
        </>
    );
};

export default CompanysMap;
