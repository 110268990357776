import React from "react";
import { ContentWrapper } from "../styled.Main.Banner";
import { Flex } from "Common/UI/Atoms";
import { useSelector } from "react-redux";

const BannerContent = ({ src, isFocus }) => {
    const { display } = useSelector(({ common }) => common);
    return (
        <ContentWrapper $isFocus={isFocus}>
            <img src={src} />
            <Flex
                margin={[0, 0, 160]}
                valign="middle"
                column
                className="content"
            >
                <h5>고객을 위한</h5>
                <Flex
                    margin={[0, 0, display?.pc ? 48 : 20]}
                    height={display?.mobile ? undefined : 72}
                >
                    <h2>
                        {"가치창조와 "}
                        {display?.mobile ? <br /> : undefined}
                        <span>완벽한</span>
                        {" 책임시공"}
                    </h2>
                </Flex>

                <p>
                    {display?.mobile
                        ? "고객을 위해 책임을 다하는 승진 기업 \n계약 사고, 공사 중단, 부실 시공 예방 시스템이 \n완비되어 있습니다"
                        : "고객을 위해 책임을 다하는 승진 기업 \n계약 사고, 공사 중단, 부실 시공 예방 시스템이 완비되어 있습니다"}
                </p>
            </Flex>
        </ContentWrapper>
    );
};

export default BannerContent;
