import React from "react";
import { Route, Routes } from "react-router-dom";
import Empty from "Pages/Empty/Empty";
import { CustomerData } from "./Data.Customer";
import { CUSTOMER, CUSTOMER_INQUIRY } from "./Route.Customer";

const CustomerRouter = () => {
    const replaceUrl = (url) => url.replace(CUSTOMER, "");

    return (
        <Routes>
            <Route path={"*"} element={<Empty url={CUSTOMER_INQUIRY} />} />
            {CustomerData?.map(({ path, Element }, key) => (
                <Route
                    path={replaceUrl(path)}
                    element={<Element />}
                    key={key}
                />
            ))}
        </Routes>
    );
};

export default CustomerRouter;
