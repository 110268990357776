import { Flex, Logo } from "Common/UI/Atoms";
import React, { useEffect, useState } from "react";
import { Wrapper, InputWrapper, CheckWrapper } from "./styled.Login";
import { useMutation } from "react-query";
import { login } from "Service/account";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const defaultData = {
        username: "",
        password: "",
    };

    const [state, setState] = useState(defaultData);
    const [save, setSave] = useState(false);
    const navigate = useNavigate();

    const [cookies, setCookie, removeCookie] = useCookies();

    const { mutate } = useMutation(login, {
        onSuccess: ({ success, userName }) => {
            if (success) {
                if (!cookies?.userID && save)
                    setCookie("userID", state?.userName, { maxAge: 2000 });
                else if (cookies?.userID && !save) removeCookie("userID");
                setCookie("username", userName);
                navigate("/");
            } else {
                alert("아이디 혹은 비밀번호가 맞지 않습니다");
            }
        },
    });

    useEffect(() => {
        if (cookies?.userID)
            setState({ ...defaultData, username: cookies.userID });
    }, []);

    const onLogin = () => {
        if (!state?.username || !state?.password)
            alert("입력되지 않은 항목이 있습니다");
        mutate(state);
    };

    const onChange = ({ target: { name, value } }) =>
        setState({ ...state, [name]: value });
    return (
        <Flex
            margin={[120, 0, 120]}
            fill
            height={"100%"}
            valign="middle"
            align="center"
        >
            <Wrapper>
                <Logo />
                <InputWrapper>
                    아이디
                    <input
                        onChange={onChange}
                        value={state?.username}
                        name="username"
                        type="email"
                        onKeyDown={({ keyCode }) => {
                            if (keyCode === 13) onLogin();
                        }}
                    />
                </InputWrapper>
                <InputWrapper>
                    비밀번호
                    <input
                        onChange={onChange}
                        value={state?.password}
                        name={"password"}
                        type="password"
                        onKeyDown={({ keyCode }) => {
                            if (keyCode === 13) onLogin();
                        }}
                    />
                </InputWrapper>
                <CheckWrapper>
                    <input
                        checked={save}
                        onChange={({ target: { checked } }) => setSave(checked)}
                        type="checkbox"
                    />
                    아이디 저장
                </CheckWrapper>
                <button onClick={onLogin}>로그인</button>
            </Wrapper>
        </Flex>
    );
};

export default Login;
