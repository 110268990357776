import { photographLoad, photographView } from "Service/perfomance";
import { useState } from "react";
import { useMutation } from "react-query";

const usePhotograph = () => {
    const [state, setState] = useState([]);
    const { mutate: onLoad } = useMutation(photographLoad, {
        onSuccess: ({ success, datas }) => {
            if (success) setState(datas);
        },
    });

    const { mutate: view } = useMutation(photographView);

    const onView = (id, Fn) =>
        view(
            { id: Number(id) },
            {
                onSuccess: ({ success, data }) => {
                    if (success) Fn(data);
                },
            }
        );

    return { state, onLoad, onView };
};

export default usePhotograph;
