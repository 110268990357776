import { InputWrapper } from "Pages/Customer/styled.Customer";
import React from "react";
import { Wrapper } from "./styled.Input";

const Input = ({ label, ...props }) => {
    return (
        <Wrapper>
            <p>{label}</p>
            <input type="text" {...props} />
        </Wrapper>
    );
};

export default Input;
