import CompanysCeo from "./Pages/Ceo/Companys.Ceo";
import CompanysOutline from "./Pages/Outline/Companys.Outline";
import CompanysPolicy from "./Pages/Policy/Companys.Policy";
import CompanysProof from "./Pages/Proof/Companys.Proof";
import CompanysOrganization from "./Pages/Organization/Companys.Organization";
import CompanysMap from "./Pages/Map/Companys.Map";
import {
    COMPANYS_CEO,
    COMPANYS_OUTLINE,
    COMPANYS_POLICY,
    COMPANYS_ORGANIZATION,
    COMPANYS_MAP,
    COMPANYS_PROOF,
} from "./Route.Companys";

export const CompanysData = [
    {
        title: "대표 인사말",
        sub: 'Representative Greeting',
        path: COMPANYS_CEO,
        Element: CompanysCeo,
    },
    { title: "회사 개요", sub: 'Company Overview', path: COMPANYS_OUTLINE, Element: CompanysOutline },
    { title: "경영방침", sub: 'Management Policy', path: COMPANYS_POLICY, Element: CompanysPolicy },
    // { title: "제증명", sub:'Proof', path: COMPANYS_PROOF, Element: CompanysProof },
    { title: "조직도", sub: 'Organization Chart', path: COMPANYS_ORGANIZATION, Element: CompanysOrganization },
    { title: "오시는 길", sub: 'Address', path: COMPANYS_MAP, Element: CompanysMap },
];
