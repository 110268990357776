import React from "react";
import { Wrapper } from "./styled.Banner";

const Banner = ({ title, sub }) => {
    return (
        <Wrapper>
            <img src="/images/banner.png" alt="banner" />
            <h2>{title}</h2>
            <h3>{sub}</h3>
        </Wrapper>
    );
};

export default Banner;
