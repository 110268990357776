import { useEffect, useRef, useState } from "react";

const useFullPage = (pageLength = 2, timer) => {
    const [idx, setIdx] = useState(0);
    const ref = useRef();
    const isDelayRef = useRef(false);
    const idxRef = useRef(idx);

    const pageMove = (idx) => {
        isDelayRef.current = true;

        setIdx(idx);

        setTimeout(() => {
            isDelayRef.current = false;
        }, timer);
    };

    const scrollCheck = (e) => {
        const isDelay = isDelayRef.current;
        const idx = idxRef.current;
        if (isDelay) return;
        const isDown = e.deltaY > 0 && pageLength - 1 !== idx;
        const isUp = e.deltaY < 0 && idx;
        if (!isDown && !isUp) return;
        pageMove(idx + (isDown ? 1 : -1));
    };

    useEffect(() => {
        idxRef.current = idx;
    }, [idx]);

    useEffect(() => {
        const target = ref.current;
        target.addEventListener("wheel", scrollCheck, {
            passive: true,
        });
        return () => {
            target.removeEventListener("wheel", scrollCheck, {
                passive: true,
            });
        };
    }, []);

    return {
        ref,
        idx,
        onTop: () => pageMove(0),
    };
};

export default useFullPage;
