import styled, { css } from "styled-components";

const alignSet = (target) => {
    switch (target) {
        case "left":
        case "top":
            return "flex-start";
        case "center":
        case "middle":
            return "center";
        case "right":
        case "bottom":
            return "flex-end";
        case "side":
            return "space-between";
        default:
            return "";
    }
};

const valueSet = (target) => {
    const isNumber =
        typeof target === "number" ||
        Number(target) >= 0 ||
        Number(target) <= 0;
    const array = Array.isArray(target);
    let style = "";
    array
        ? target.map((i) => {
            return (style += typeof i === "number" ? i + "rem " : i + " ");
        })
        : (style += isNumber ? `${target}rem` : target);

    return target === undefined ? target : style;
};

const gridSet = (target) => {
    const type = typeof target;
    const isArray = Array.isArray(target);
    let style = "";
    isArray
        ? target.map((i) => {
            return (style += typeof i === "number" ? i + "fr " : i + " ");
        })
        : (style += type === "number" ? `repeat(${target},1fr)` : target);
    return style;
};

export const Wrapper = styled.div`
    ${({
    $gap,
    $width,
    $height,
    $margin,
    $padding,
    $flex,
    $column,
    $row,
    $align,
    $valign,
}) => css`
        display: grid;
        ${$row
        ? css`
                  grid-template-rows: ${gridSet($row)};
              `
        : ""}
        ${$column
        ? css`
                  grid-template-columns: ${gridSet($column)};
              `
        : ""}
        gap: ${valueSet($gap)};
        justify-content: ${alignSet($align)};
        align-items: ${alignSet($valign)};
        ${$width
        ? css`
                  width: ${valueSet($width)};
              `
        : ""}
        ${$height
        ? css`
                  height: ${valueSet($height)};
              `
        : ""}
        ${$margin
        ? css`
                  margin: ${valueSet($margin)};
              `
        : ""}
        ${$padding
        ? css`
                  padding: ${valueSet($padding)};
              `
        : ""}
        ${$flex
        ? css`
                  flex: 1;
              `
        : ""}
        position: relative;
    `}
`;
