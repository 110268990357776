import { Flex } from "Common/UI/Atoms";
import { styled } from "styled-components";

export const Wrapper = styled(Flex).attrs({
    column: true,
    align: 'center',
    padding: [80, 0]
})`
width:100%;
background-color:#F2F5FC ;
margin-bottom:120rem;
        @media (max-width:768px){
            padding:28rem 16rem;
        margin-bottom:40rem;
        }
.content{
    width:1200rem;
    background-color:#fff;
        @media (max-width:768px){
            width:100%;
            flex-direction:column;
        }
    img{
        
        width:480rem;
        @media (max-width:768px){
            width:100%;
        }
    }
    p{
        white-space:pre-line;
        padding:32rem 40rem 120rem;
        word-break: keep-all;
        
        @media (max-width:768px){
        padding:28rem 16rem 56rem;
        }
        font-size:16rem;
        font-weight:500;
        color:#666;
    }
    .name{
        position:absolute;
        bottom:0;
        left:-60rem;
        background-color: #333333;
        padding:30rem 60rem;
        width:600rem;
        @media (max-width:768px){
            bottom:-28rem;
            left:0;
            height:56rem;
        padding:0 40rem;
        width:300rem;
            
        }
        h5{
            color:#fff;
            font-size:18rem;
        @media (max-width:768px){
            font-size:16rem;
        }
            font-weight:300;
            b{
                font-weight:600;
            }
        }
    }
}
`