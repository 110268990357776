import { Flex } from "Common/UI/Atoms";
import { css, styled } from "styled-components";

export const Wrapper = styled(Flex).attrs({
    width: '100vw',
    align: 'center',
})`
        margin-bottom:120rem;
        height:80rem ;
        @media (max-width:768px){
            overflow-x:scroll;
            height:48rem ;
        margin-bottom:64rem;

        }
        div{
            height:100%;
            gap: 60rem;
        @media (max-width:768px){
            gap: 8rem;
            padding:0 16rem;
            left:0;
            position:absolute;
            width:max-content;
            min-width:100%;
            justify-content:center;
            &::after{
                content:'';
                position:absolute ;
                width:100%;
                height:1rem;
                background-color: #C8C8C8;
                bottom:0;
            }
        }
        }
        
    @media (min-width:769px){
        &::after{
            content:'';
            position:absolute ;
            width:100%;
            height:1rem;
            background-color: #C8C8C8;
            bottom:0;
            left:0;
        }
    }
`

export const ButtonWrapper = styled.button`
${({ $isActive }) => css`
    height:100%;
    padding:12rem;
    position:relative;
    z-index:1;
    font-size:16rem;
        @media (max-width:768px){
            font-size:14rem;
        }
    color:${$isActive ? '#2044AC' : "#666"};
    ${$isActive ? css`
        &::after{
            content:'';
            position:absolute ;
            width:100%;
            height:2rem;
            background-color: #2044AC;
            bottom:0;
            left:0;
        }
    `: css`
    &:hover{
        color:#2044AC;
    }
    `}
    `}
`