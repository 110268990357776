import { Banner, Flex } from "Common/UI/Atoms";
import { Tab } from "Common/UI/Molecules";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IntroductionData } from "./Data.Introduction";
import IntroductionRouter from "./Introduction.Router";

const Introduction = () => {
    const location = useLocation();
    const [state, setState] = useState({
        title: "",
        sub: "",
    });

    useEffect(() => {
        const target = IntroductionData?.find(
            ({ path }) => location.pathname === path
        );
        setState({
            title: target?.title,
            sub: target?.sub,
        });
    }, [location]);

    return (
        <>
            <Banner {...state} />
            <Tab
                list={IntroductionData?.map(({ path, title }) => ({
                    to: path,
                    title,
                }))}
            />
            <Flex align={"center"} column>
                <IntroductionRouter />
            </Flex>
        </>
    );
};

export default Introduction;
