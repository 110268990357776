import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import commonSlice from "./Common/CommonSlice";

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["account"],
};

const reducers = combineReducers({
    common: commonSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    }),
});
