import { styled } from "styled-components";
import { headerHeight } from "../../../Utils/Utils";

export const Wrapper = styled.section`
    display: flex;
    flex-direction:column;
    
            @media (min-width:769px){
                height:calc(100vh - ${headerHeight}rem);
                min-height:calc(100vh - ${headerHeight}rem);
            }
            @media (max-width:768px){
                height:calc(100vh - 60rem);
                min-height:calc(100vh - 60rem);
            }
    overflow-y:scroll ;
`