import IntroductionInfo from "./Pages/Info/Introduction.Info";
import IntroductionPerform from "./Pages/Perform/Introduction.Perform";
import { INTRODUCTION_BUSINESS, INTRODUCTION_CONSTRUCTION } from "./Route.Introduction";



export const IntroductionData = [
    {
        title: "사업 소개",
        sub: 'Business Introduction',
        path: INTRODUCTION_BUSINESS,
        Element: IntroductionInfo,
    },
    { title: "공사 수행", sub: 'Construction Introduction', path: INTRODUCTION_CONSTRUCTION, Element: IntroductionPerform },
];
