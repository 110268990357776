import axios from "axios";
import { SERVER_URL } from "./env";

//공통 axios 인스턴스 생성
export const request = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    baseURL: SERVER_URL,
    withCredentials: true,
    timeout: 100_000,
});
//공통 axios 성공,실패 관리 함수
request.interceptors.request.use(
    (config) => config,
    (error) => console.error(error)
);

export const requestApi = async (url, config, method = "post") => {
    try {

        const formData = new FormData();
        for (let name in config) {
            formData.append(name, config[name]);
        }
        const response = await request[method](
            `${url}/index.php`,
            config
        );
        return response.data;
    } catch (e) {
        // console.log(e);
    }
};

const onSetRequestDataByMethod = (method, config) => {
    switch (method) {
        case "get":
            return {
                params: {
                    // ...common,
                    ...config,
                },
            };
        case "delete":
            return {
                data: {
                    ...config,
                },
            };
        default:
            return {
                ...config,
            };
    }
};
