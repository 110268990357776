import { Flex } from "Common/UI/Atoms";
import { css, styled } from "styled-components";


export const valueSet = (target) => {
    const isNumber =
        typeof target === "number" ||
        Number(target) >= 0 ||
        Number(target) <= 0;
    const array = Array.isArray(target);
    let style = "";
    array
        ? target.map((i) => {
            return (style += typeof i === "number" ? i + "rem " : i + " ");
        })
        : (style += isNumber ? `${target}rem` : target);

    return target === undefined ? target : style;
};

export const Wrapper = styled.div`
    ${({ theme: { modal }, $padding, $size, $timer, $isActive }) => {
        const sizeX = typeof $size === "number" ? $size : modal.size[$size];
        return css`
            display: flex;
            background-color: #fff;
            border-radius: 12rem;
            box-shadow: #000;
            width: ${valueSet(sizeX)};
            flex-direction: column;
            transition: opacity ${$timer}ms;
            overflow: hidden;
            opacity: ${$isActive ? 1 : 0};
            font-size:20rem;
            max-width:100vw;
            .modalContent {
                padding: ${valueSet($padding)};
            }
            .modalFooter {
                padding: 0 32rem;
                height: 60rem;
                background-color: #ddd;
                button{
                    font-size:16rem;
                }
            }
        `;
    }}
`;

export const InfoWrapper = styled(Flex)`
    color: var(--G_500);
    white-space: pre-line;
    font-size: 14rem;
`;

export const IntervalWrapper = styled(Flex)`
    background-color: var(--G_20);
    width: 280rem;
    height: 680rem;
    padding: 24rem;
`;
