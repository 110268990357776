import { Route, Routes } from 'react-router-dom';
import Test from './Pages/Test/Test';
import Main from './Pages/Main/Main';
import Header from './Pages/Header/Header';
import { Flex, Layout } from './Common/UI/Atoms';
import Companys from 'Pages/Companys/Companys';
import Empty from 'Pages/Empty/Empty';
import { COMPANYS } from 'Pages/Companys/Route.Companys';
import Footer from 'Pages/Footer/Footer';
import { INTRODUCTION } from 'Pages/Introduction/Route.Introduction';
import Introduction from 'Pages/Introduction/Introduction';
import { CUSTOMER } from 'Pages/Customer/Route.Customer';
import Customer from 'Pages/Customer/Customer';
import { LOGIN } from 'Pages/Login/Route.Login';
import Login from 'Pages/Login/Login';
import { styled } from 'styled-components';
import { PERFORMANCE } from 'Pages/Performance/Route.Performance';
import Performance from 'Pages/Performance/Performance';
import { useEffect } from 'react';
import useDisplay from 'Hooks/useDisplay';
import MainMobile from 'Pages/Main/Main.Mobile';

function App() {
  const { display } = useDisplay()
  return (
    <>
      <Header />
      <Layout>
        <Routes>
          <Route path={'/'} element={
            <>
              <Main />  <MainMobile />
            </>
            // <div className="App">
            //   <header className="App-header">
            //     <p>
            //       서비스 준비 중입니다
            //     </p>
            //   </header>
            // </div>
          } />

          <Route path={'*'} element={
            <Flex fill column height={"max-content"} >
              <Routes>
                <Route path={COMPANYS + '/*'} element={<Companys />} />
                <Route path={INTRODUCTION + '/*'} element={<Introduction />} />
                <Route path={CUSTOMER + '/*'} element={<Customer />} />
                <Route path={PERFORMANCE + '/*'} element={<Performance />} />
                <Route path={LOGIN} element={<Login />} />
                <Route path={'/test'} element={<Test />} />
                <Route path={'/*'} element={<Empty url={'/'} />} />
              </Routes>

              <Footer />
            </Flex>
          } />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
