export const {
    REACT_APP_API,
    REACT_APP_BUILD_MODE,
} = process.env;
//스마플
export const SERVER_URL = REACT_APP_API;

const IS_DEVELOP = REACT_APP_BUILD_MODE === "DEVELOPMENT"
// 배포 환경에서 console.log, console.warn 지우기
if (IS_DEVELOP) {
    // console.log = function no_console() { };
    console.warn = function no_console() { };
}
