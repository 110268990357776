import React from "react";
import { Wrapper } from "./styled.Main.Inquiry";
import { Flex } from "Common/UI/Atoms";
import InquiryBox from "./Components/InquiryBox";
import { useSelector } from "react-redux";

const MainInquiry = () => {
    const { display } = useSelector(({ common }) => common);
    return (
        <Wrapper
            gap={display?.mobile ? 20 : 60}
            padding={display?.mobile ? [20, 0] : undefined}
        >
            <Flex className="contentHeader" column align={"center"}>
                <h5>{"오시는 길 & 문의 사항"}</h5>

                <Flex height={67}>
                    <h2>
                        {"Location & "}
                        <span>Inquiry</span>
                    </h2>
                </Flex>
            </Flex>
            <InquiryBox display={display} />
        </Wrapper>
    );
};

export default MainInquiry;
