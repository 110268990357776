import { styled } from "styled-components";
import { footerHeight } from "../../Common/Utils/Utils";
import { Link } from "react-router-dom";


export const Wrapper = styled.footer`
    display: flex;
    flex-direction:column;
    
            @media (min-width:769px){
                height: ${footerHeight}rem;
            }
    background-color: #333;
    align-items:center;
    .copyright{
        height:60rem;
        background-color:#454545;
        
        p{
            @media (min-width:769px){
                width:1440rem;
            }
            color:#999;
            font-size:14rem;
        }
    }
`;

export const TopWrapper = styled.div`

            @media (min-width:769px){
                width:1440rem;
                height:244rem;
                padding:40rem 0;
            }
            @media (max-width:768px){
                width:100%;
                padding:16rem 12rem;
                flex-direction:column;
                gap:12rem;
                .logo{
                    margin-bottom:20rem;
                }
            }
    display:flex ;
    justify-content:space-between;
    color:#ccc;
    h5{
        font-size:14rem;
        width:100rem;
    }
    @media (max-width:768px){
        p{
            flex:1;
        }
    }
`
export const TopButtonWrapper = styled.button`
display:flex ;
cursor:pointer;
align-items:center;
justify-content:center;
background-color:#fff;
width:60rem ;
height:60rem;
border-radius:100%;
svg{
    width:24rem;
}
`

export const LinkWrapper = styled(Link)`
    display:flex ;
    gap:12rem;
    align-items:center;
    background-color:#565656;
    
    @media (min-width:769px){
                width:260rem;
                height:60rem;
            }
    @media (max-width:768px){
                height:48rem;
            }
    padding:0 20rem;
    color:#fff;
    p{
        font-size:16rem;
        font-weight:300;
        b{
            font-weight:600;
        }
    }
`