import { styled } from "styled-components";

export const Wrapper = styled.label`
display:flex;
flex-direction:column;
flex:1;
gap:12rem;
    p{
        font-size:18rem;
        font-weight:400;
        color:#999;
    @media (max-width:768px){
        font-size:14rem;
    }
    }
    input{
        background-color: rgba(249, 249, 249, 0.98);
        color:#666666;
        height:36rem;
        font-size:18rem;
        padding:0 32rem;
        background:#eee;
    @media (max-width:768px){
        height:56rem;
        padding:0 20rem;

    }
    
    }
    
    textarea{
        background-color: rgba(249, 249, 249, 0.98);
        color:#666666;
        height:320rem;
        font-size:18rem;
        padding:24rem 32rem;
    @media (max-width:768px){
        padding:18rem 20rem;
    }
    }
    @media (max-width:768px){
        width:100%;
        input,textarea{
        font-size:16rem;
            width:100%;
        }
    }
`