import { Flex } from "Common/UI/Atoms";
import { styled } from "styled-components";

export const Wrapper = styled(Flex)`
    width:1200rem;
    margin-bottom:120rem;
        @media (max-width:768px){
            width:100%;
            padding:0 16rem;
            margin-bottom:40rem;
        }
`

export const ListWrapper = styled(Flex)`
.titleBox{
    width:160rem;
    height:60rem;
    font-size:18rem;
    @media (max-width:768px){
        width:120rem;
        font-size:16rem;
    }
    font-weight:500;
    border-bottom:1rem solid #555;
}
.contentBox{
    height:60rem;
    padding-left:40rem;
    font-size:16rem;
    @media (max-width:768px){
        padding-left:16rem;
        font-size:14rem;
    }
    color:#333;
    border-bottom:1rem solid #ddd;
}
`