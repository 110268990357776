import { Flex } from "Common/UI/Atoms";
import { styled } from "styled-components";

export const Wrapper = styled(Flex).attrs({
    column: true,
    width: 520,
    align: 'center',
    gap: 12,
    padding: [40, 20]
})`
    border:2rem solid #ededed;
    border-radius:12rem;
    button{
        width:100%;
        background-color:#036EB8;
        height:60rem;
        color:#fff;
        font-size:18rem;
        border-radius:4rem;
    }
`
export const InputWrapper = styled.label`
    display:flex;
    flex-direction:column;
    width:100%;
    font-size:18rem;
    gap:12rem;
    input{
        height:60rem;
        padding:0rem 12rem;
        font-size:18rem;
        background-color:#fafafa;
    }
`

export const CheckWrapper = styled.label`
    align-self:flex-start;
    margin: 12rem;
    display:flex ;
    gap:8rem;
    input{
        width:20rem;
    }
`