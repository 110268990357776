import { css, styled } from "styled-components";
import { headerHeight, headerMobileHeight } from "../../Common/Utils/Utils";
import { Link } from "react-router-dom";

export const Wrapper = styled.header`
    width:100vw;
    background-color:#fff;
    display:flex ;
    align-items:center;
    @media (min-width:769px){
      padding-left:80rem;
      height:${headerHeight}rem;
    }
    
      @media (max-width:768px){
      padding-left:36rem;
      height:${headerMobileHeight}rem;
      }
`

export const ButtonWrapper = styled.button`
    cursor:pointer;
    background-color:inherit;
    display:flex ;
    padding:8rem;
    height:100%;
    aspect-ratio: 1/1;
    justify-content:center;
    align-items:center;
    background-color: #2044AC ;
    @media (min-width:769px){
      display:none ;
    }
`

export const MenuWrapper = styled.div`${({ $isActive }) => css`
    display:flex;
    flex-direction:column;
    cursor:pointer;
    position:relative;
    width:26rem;
    height:26rem;
    align-items:flex-end;
    justify-content:center;
    span{
        position:absolute;
        height: 2rem;
        background-color:#fff;
        transition:300ms ;
    ${$isActive ? css`
        width:100%;
      &:nth-child(1){
        top:calc(50% - 1rem);
        transform: rotate(45deg);
      }
      &:nth-child(2){
        opacity:0;
    }
    &:nth-child(3){
        bottom:calc(50% - 1rem);;
        transform: rotate(-45deg);
      }
    ` : css`
    opacity:1 ;
        transform: rotate(0);
      &:nth-child(1){
        width:90%;
        top:4rem;
      }
      &:nth-child(2){
        width:80%;
    }
    &:nth-child(3){
        bottom:4rem;
        width:100%;
      }
    `}
    }

`}
`

export const LinkWrapper = styled.div`
  display:flex ;
  gap:60rem;
  height:100%;
  
      @media (max-width:768px){
        display:none ;
      }
`

export const CustomerButton = styled(Link)`
            @media (min-width:769px){
                width:${headerHeight}rem ;
                height:${headerHeight}rem;
            }
            @media (max-width:768px){
              display:none ;
                width:${headerMobileHeight}rem ;
                height:${headerMobileHeight}rem;
            }
  background-color:#5F5F5F ;
  display:flex ;
  align-items:center;
  justify-content:center;
  `

export const MoveWrapper = styled(Link)`
  ${({ $isActive }) => css`
  
    display:flex ;
    justify-content:center;
    align-items:center;
    width:100rem;
    height:100%;
    font-size:20rem;
    font-weight:500;
    color:#444;
      border-bottom:2rem solid transparent;
    ${$isActive ? css`
      color: #2044AC;
      border-bottom-color: #2044AC;
    `: css`
    
    &:hover{
      color: #2044AC;
      border-bottom-color: #2044AC;
    }
    `}
  `}
`

export const NaviWrapper = styled.nav`
${({ $isActive }) => css`
    display:flex ;
    flex-direction:column;
    padding:0 16rem;
    position: fixed;
    background-color:#fff ;
    top:0;
    right:0;
    z-index:10;
    transform: translateX(${$isActive ? 0 : 100}%);
    transition:transform 0.3s ;
        height:100vh;
    button{
      display:flex ;
      align-items:center;
      h4{
        font-weight:700;
            color:#777;
      }
      p{
        color:#ccc;
      }
    }
    @media (max-width:768px){
        width:100vw ;
        gap:24rem;
        button{
          height:60rem;
          gap:16rem;
          h4{
            font-size:18rem;
          }
          p{
            font-size:14rem;
          }
        }
    }
`}
`