import React, { forwardRef } from "react";
import { Wrapper } from "./styled.Flex";

const Flex = forwardRef(
    /**
     *
     * @param {{
     *   className?:string
     *   width?:number|'max-content'|'min-content'|'fit-content'|'100%'
     *   height?:number|'max-content'|'min-content'|'fit-content'|'100%'
     *   style?:css
     *   gap?:number
     *   align?:'left'|'center'|'right'|'side'
     *   valign?:'top'|'middle'|'bottom'|'side'
     *   margin?:number[]
     *   padding?:number[]
     *   children:JSX.Element
     *   column:boolean
     *   fill:boolean
     *   radius:number
     * }} param0
     * @param {ref} ref
     * @returns
     */
    (
        {
            className,
            width,
            height,
            style,
            gap,
            column,
            align,
            radius,
            border,
            valign = column ? undefined : "middle",
            margin,
            children,
            padding,
            fill,
            ...props
        },
        ref
    ) => {
        return (
            <Wrapper
                className={className}
                $gap={gap}
                $align={align}
                $valign={valign}
                $padding={padding}
                $radius={radius}
                $border={border}
                $margin={margin}
                $column={column}
                $width={width}
                $height={height}
                $flex={fill}
                ref={ref}
                {...props}
            >
                {children}
            </Wrapper>
        );
    }
);

export default Flex;
