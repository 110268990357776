import { css, styled } from "styled-components";
import { Flex } from "../../Common/UI/Atoms";
import { footerHeight, headerHeight } from "../../Common/Utils/Utils";

export const MainLayout = styled.section`
    display:flex ;
    flex-direction:column;
    @media (min-width:769px){
        overflow:hidden;
        height:calc(100vh - ${headerHeight}rem);
    }
`

export const Scroll = styled.div`${({ $idx, $timer, $isLast = false }) => css`
    display:flex ;
    flex-direction:column;
    height:max-content;
    transform:translateY(calc(${$idx * -100}vh + ${($idx * headerHeight) - ($isLast ? footerHeight : 0)}rem));
    transition:transform ${$timer}ms;
    >div{
        display:flex ;
        height:calc(100vh - ${headerHeight}rem);
    }
    .contentHeader{
        
        h5{
            font-size:22rem;
            color:#999;
            margin-bottom:20rem;
        }
        h2{
            font-size:56rem;
            font-weight:600;
            margin-bottom:32rem;
            line-height:67rem;
            span{
                display: contents ;
            }
        }
        p{
            font-size:20rem;
        }   
    }
`}
`
