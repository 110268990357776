import React from "react";
import { Route, Routes } from "react-router-dom";
import Empty from "Pages/Empty/Empty";
import { IntroductionData } from "./Data.Introduction";
import { INTRODUCTION, INTRODUCTION_BUSINESS } from "./Route.Introduction";

const IntroductionRouter = () => {
    const replaceUrl = (url) => url.replace(INTRODUCTION, "");

    return (
        <Routes>
            <Route path={"*"} element={<Empty url={INTRODUCTION_BUSINESS} />} />
            {IntroductionData?.map(({ path, Element }, key) => (
                <Route
                    path={replaceUrl(path)}
                    element={<Element />}
                    key={key}
                />
            ))}
        </Routes>
    );
};

export default IntroductionRouter;
