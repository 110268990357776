import { Flex } from "Common/UI/Atoms";
import { styled } from "styled-components";

export const Wrapper = styled(Flex).attrs({
    column: true,
    align: 'center',
    gap: 40,
    margin: [0, 0, 80]
})`
        @media (max-width:768px){
            padding:0 16rem;
            gap:28rem;
            margin-bottom:40rem;
        }
    h3{
        color:#333;
        font-size:36rem;
        @media (max-width:768px){
        font-size:24rem;
        }
        font-weight:600;
    }
    >span{
        width:20rem;
        height:4rem;
        background-color:#2044AC;
    }
    p{
        font-size:24rem;
        @media (max-width:768px){
        font-size:14rem;
        }
        text-align:center;
        color:#333;
        b{
            font-weight:500;
            color:#2044AC;
        }
    }
`