import React from "react";
import { ListWrapper } from "../styled.Companys.Outline";
import { Flex } from "Common/UI/Atoms";

const OutlineList = ({ icon, title, info }) => (
    <ListWrapper>
        <Flex className="titleBox" gap={12}>
            {icon}
            {title}
        </Flex>
        <Flex className="contentBox" fill>
            {info}
        </Flex>
    </ListWrapper>
);

export default OutlineList;
