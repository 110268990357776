
import CustomerInquiry from "./Pages/Inquiry/Customer.Inquiry";
import {
    CUSTOMER_INQUIRY,
} from "./Route.Customer";

export const CustomerData = [
    {
        title: "문의 사항",
        sub: 'Inquiry',
        path: CUSTOMER_INQUIRY,
        Element: CustomerInquiry,
    },
];
