import React from "react";
import { LinkWrapper, MoveWrapper } from "../styled.Header";
import { Link, useLocation } from "react-router-dom";
import { COMPANYS, COMPANYS_CEO } from "Pages/Companys/Route.Companys";
import {
    INTRODUCTION,
    INTRODUCTION_BUSINESS,
} from "Pages/Introduction/Route.Introduction";
import { CUSTOMER, CUSTOMER_INQUIRY } from "Pages/Customer/Route.Customer";
import {
    PERFORMANCE,
    PERFORMANCE_CONSTRUCTION,
} from "Pages/Performance/Route.Performance";
import { LinkData } from "../Data.Header";

const LinkList = ({ isLogin }) => {
    const location = useLocation();
    return (
        <LinkWrapper>
            {LinkData.map(({ to, title, target }, key) => {
                const isActive = location.pathname.includes(target) && !!to;
                return (
                    <MoveWrapper $isActive={isActive} key={key} to={to}>
                        {title}
                    </MoveWrapper>
                );
            })}
        </LinkWrapper>
    );
};

export default LinkList;
