import React from "react";
import { Wrapper } from "./styled.Content";

const Content = ({ title, info }) => {
    return (
        <Wrapper>
            <h3>{title}</h3>
            <span />
            {info ? <p>{info}</p> : undefined}
        </Wrapper>
    );
};

export default Content;
