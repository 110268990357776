import React from "react";
import { LabelWrapper } from "../../../styled.Performance.Photograph";
import { useMutation } from "react-query";
import { fileUpload } from "Service/common";
import moment from "moment";

const Upload = ({ onChange }) => {
    const base64Reader = (i) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(i);
            reader.onload = () => resolve(reader.result);
        });
    };

    const { mutate: temp } = useMutation(fileUpload);
    const fileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            temp({ file });

            const url = await base64Reader(file);
            await onChange({
                name: file?.name,
                date: moment().format("YYYY-MM-DD"),
                url,
            });
        }
    };
    return (
        <LabelWrapper>
            <input type="file" onChange={fileChange} accept="image/*" />
            파일 업로드
        </LabelWrapper>
    );
};

export default Upload;
