import { Content } from "Common/UI/Molecules";
import React from "react";
import { Wrapper } from "./styled.Companys.Ceo";
import { Flex } from "Common/UI/Atoms";

const CompanysCeo = () => {
    return (
        <>
            <Content
                title={"CEO 인사말"}
                info={
                    <>
                        주식회사 승진기업의 최우선 가치는
                        <br />
                        <b>“고객을 위한 가치창조와 완벽한 책임시공”</b>으로 이를
                        위하여 끊임없이 노력하고 있습니다
                    </>
                }
            />
            <Wrapper>
                <Flex className="content">
                    <img src="/images/reeting.png" />
                    <Flex fill className="comment">
                        <p>{`주식회사 승진기업의 최우선 가치는 "고객을 위한 가치창조 와 완벽한 책임시공"으로
이를 위하여 끊임없이 노력하고 있습니다.
또한 고객 만족을 극대화하는 것이 주식회사 승진기업의 최종 목표입니다.

주식회사 승진기업는 보온 분야를 선도할 수 있는 국내 최고의 기업으로 성장할 계획을 가지고 있으며, 오랜 기간 축적된 기술력을 바탕으로 발전소 및 석유화학, 정유사에서 보온 분야 공사를 성공리에 수행해왔습니다.

앞으로도 최고의 품질과 완벽한 기술력으로 고객이 인정하는 "믿음의 기업”으로 도약하기 위해 전 직원이 노력하고 있습니다.

전 직원의 최선의 노력으로 귀사의 사업에 도움이 되는 협력 업체가 되겠습니다.
감사합니다.
                    `}</p>
                        <Flex className="name">
                            <h5>
                                <b>강기식</b> 대표이사
                            </h5>
                        </Flex>
                    </Flex>
                </Flex>
            </Wrapper>
        </>
    );
};

export default CompanysCeo;
