import React, { useEffect, useState } from "react";
import { Wrapper } from "./styled.Main.Banner";
import Gauge from "./Components/Gauge";
import { Flex } from "Common/UI/Atoms";
import BannerContent from "./Components/BannerContent";
import { useSelector } from "react-redux";

const MainBanner = () => {
    const timer = 3000;
    const images = [
        "/images/banner1.png",
        "/images/banner2.png",
        "/images/banner3.png",
    ];
    const length = images?.length;
    const [gauge, setGauge] = useState(0);
    const [current, setCurrent] = useState(0);
    useEffect(() => {
        if (gauge === 100) {
            setGauge(0);
            setCurrent(length - 1 === current ? 0 : current + 1);
        } else {
            setTimeout(() => {
                setGauge(gauge + 1);
            }, timer / 100);
        }
    }, [gauge]);
    return (
        <Wrapper>
            {Array(length)
                .fill(0)
                .map((_, key) => (
                    <BannerContent
                        src={images[key]}
                        key={key}
                        isFocus={key === current}
                    />
                ))}
            {/* <Gauge timer={timer} gauge={gauge} /> */}
        </Wrapper>
    );
};

export default MainBanner;
