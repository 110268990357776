import { Flex } from "Common/UI/Atoms";
import { PerformWrapper } from "Pages/Introduction/styled.Introduction";
import React from "react";

const PerformList = ({ src, valign, title, sub, reverse }) => {
    return (
        <PerformWrapper $isReverse={reverse} column valign={valign}>
            <img src={src} />
            <Flex
                align={reverse ? "right" : undefined}
                valign={reverse ? "bottom" : undefined}
                column
            >
                <h4>{title}</h4>
                <p>{sub}</p>
            </Flex>
        </PerformWrapper>
    );
};

export default PerformList;
