import { Flex } from "Common/UI/Atoms";
import { styled } from "styled-components";

export const Wrapper = styled(Flex).attrs({
    column: true,
    align: 'center',
    padding: [80, 0]
})`
width:100%;
background-color:#F2F5FC ;
margin-bottom:120rem;
    @media (max-width:768px){
        flex-direction:column;
        padding:28rem 16rem;
        .map{
            width:100%;
            height:184rem;
        }
    }
`

export const MapWrapper = styled(Flex)`
    .customoverlay{
        background:#fff ;
        width:34rem;
        height:34rem;
        padding:2rem;
        border-radius:100%;
        box-shadow: 1rem 1rem 5rem gray;
        a{
            color:#333;
        }
        img{
            width:30rem;
            height:30rem;
        }
    }
`