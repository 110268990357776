import { Flex } from "Common/UI/Atoms";
import { styled } from "styled-components";

export const Wrapper = styled(Flex).attrs({
    align: 'center',
    valign: "middle",
    column: true,

})`
background:#EEF3FF ;
        .contentHeader{
            
            h2{
                color:#333;
                font-weight:400;
                span{
                    color:#2044AC;
                    font-weight:600;
                }
            }
        }
`

export const BoxWrapper = styled(Flex)`
    
    @media (min-width:769px){
        width:1440rem;
        height:470rem;
    }
    >:first-child{
        background-color:#E1E1E1;
    }
    .infoBox{
        width:100%;
        height:420rem;
        background-color:#fff ;
    }
    .link{
        position:absolute ;
        left:0;
        bottom:0;
        background-color:#333 ;
        
        @media (min-width:769px){
            width:660rem;
        height:84rem;
        padding:0 60rem;
        }
    @media (max-width:768px){
            width:100%;
        height:60rem;
        padding:0 32rem;
        
    }
        display:flex;
        align-items:center;
        justify-content:space-between;
        color:#fff;
        p{
            
            @media (min-width:769px){
                font-size:18rem;
            }
    @media (max-width:768px){
                font-size:14rem;
        
    }
            font-weight:300;
            b{
                font-weight:600;
            }
        }
    }
`
export const ListWrapper = styled(Flex)`
.titleBox{
    @media (min-width:769px){
        width:160rem;
        height:60rem;
        font-size:18rem;
    }
    @media (max-width:768px){
        width:100rem;
        height:56rem;
        font-size:16rem;
    }
    font-weight:500;
    border-bottom:1rem solid #555;
}
.contentBox{
    @media (min-width:769px){
        height:60rem;
        font-size:16rem;
        padding-left:40rem;
    }
    @media (max-width:768px){
        height:56rem;
        font-size:14rem;
        padding-left:32rem;
    }
    color:#333;
    border-bottom:1rem solid #ddd;
}
`