import { useState } from "react";

/**
 * 데이터 작성값
 * @param {{any}} initiaState - 기본 지정 값
 */
const useInput = (initiaState) => {
    const [state, setState] = useState({ ...initiaState });

    /**
     *
     * @param {{target:{value:string}}|{any}} e
     */
    const onChange = (e) => {
        const data = e?.target
            ? {
                  [e.target.name]:
                      e.target.value === undefined
                          ? e.target.checked
                          : e.target.value,
              }
            : e;
        setState({
            ...state,
            ...data,
        });
    };
    const onChangeByName = (name, value) =>
        setState({
            ...state,
            [name]: value,
        });

    const onReset = () => setState(initiaState);

    /**
     *
     * @param {string[]} filters
     * @param {boolean} isZero
     */
    const valuesCheck = (filters, isZero = true) => {
        let isWarning = false;
        filters?.map((name) => {
            const data = state?.[name];
            if (isZero) {
                if (typeof data !== "number" && !data) isWarning = true;
            } else if (!data) isWarning = true;
        });

        return isWarning;
    };

    return {
        state,
        setState,
        onReset,
        onChange,
        onChangeByName,
        valuesCheck,
    };
};

export default useInput;
