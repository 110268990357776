import React, { useEffect, useState } from "react";
import {
    ButtonWrapper,
    CustomerButton,
    MenuWrapper,
    Wrapper,
} from "./styled.Header";
import { Flex, Icon, Logo } from "../../Common/UI/Atoms";
import LinkList from "./Components/LinkList";
import { useLocation, useNavigate } from "react-router-dom";
import { CUSTOMER_INQUIRY } from "Pages/Customer/Route.Customer";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import Navi from "./Components/Navi";

const Header = () => {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [cookies, _, removeCookie] = useCookies();
    const { username } = cookies;
    const location = useLocation();
    useEffect(() => {
        if (username && !isLogin) setIsLogin(true);
        else if (!username && isLogin) {
            setIsLogin(false);
            removeCookie("username");
            location.reload();
        }
    }, [location]);
    const { display } = useSelector(({ common }) => common);
    return (
        <>
            <Wrapper>
                <Flex height={"100%"} fill gap={160}>
                    <Logo mobileSize={120} onClick={() => navigate("/")} />
                    <LinkList isLogin={isLogin} />
                </Flex>
                {username ? (
                    <button
                        onClick={() => {
                            setIsLogin(false);
                            removeCookie("username");
                            navigate("/");
                        }}
                    >
                        로그아웃
                    </button>
                ) : undefined}
                <CustomerButton to={CUSTOMER_INQUIRY}>
                    <Icon.Sns size={display?.mobile ? 32 : 48} fill={"#fff"} />
                </CustomerButton>
                <ButtonWrapper onClick={() => setIsActive(!isActive)}>
                    <MenuWrapper $isActive={isActive}>
                        <span />
                        <span />
                        <span />
                    </MenuWrapper>
                </ButtonWrapper>
            </Wrapper>
            <Navi isActive={isActive} onClose={() => setIsActive(false)} />
        </>
    );
};

export default Header;
