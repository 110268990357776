import { requestApi } from "./Config";

export const cnstructionLoad = async (config) => requestApi('construction/load', config)
export const cnstructionSave = async (config) => requestApi('construction/save', config)
export const cnstructionDelete = async (config) => requestApi('construction/delete', config)

export const photographLoad = async (config) => requestApi('photograph/load', config)
export const photographSave = async (config) => requestApi('photograph/save', config)
export const photographView = async (config) => requestApi('photograph/view', config)
export const photographDelete = async (config) => requestApi('photograph/delete', config)
