import React, { useEffect, useState } from "react";
import PerformanceRouter from "./Performance.Router";
import { PerformanceData } from "./Data.Performance";
import { useLocation } from "react-router-dom";
import { Banner, Flex } from "Common/UI/Atoms";
import { Tab } from "Common/UI/Molecules";

const Performance = () => {
    const location = useLocation();
    const [state, setState] = useState({
        title: "",
        sub: "",
    });

    useEffect(() => {
        const target = PerformanceData?.find(
            ({ path }) => location.pathname === path
        );
        setState({
            title: target?.title,
            sub: target?.sub,
        });
    }, [location]);
    return (
        <>
            <Banner {...state} />
            <Tab
                list={PerformanceData?.map(({ path, title }) => ({
                    to: path,
                    title,
                }))}
            />
            <Flex align={"center"} column>
                <PerformanceRouter />
            </Flex>
        </>
    );
};

export default Performance;
