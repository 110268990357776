import { Flex, Grid } from "Common/UI/Atoms";
import { css, styled } from "styled-components";

export const InfoWrapper = styled(Flex)`
        @media (max-width:768px) {
            justify-content:flex-start;
        }
    img{
        position:absolute;
        width:100%;
        height:800rem;
        object-fit:cover;
        @media (max-width:768px) {
            height:500rem;
        }
    }
    >div{
        width:100%;
        padding:40rem 40rem 60rem;
        z-index:1;
        background-color:rgba(0,0,0,0.5);
        @media (max-width:768px) {
            height:100rem;
            justify-content:center;
            padding:0 20rem;
            svg{
                display:none;
            }
        }
        h4{
            font-size:40rem;
            font-weight:500;
            color:#fff;
            margin-top:40rem;
            margin-bottom:12rem;
            @media (max-width:768px) {
                margin-top:0;
                font-size:20rem;
            }
        }
        p{
            font-size:18rem;
            font-weight:500;
            color:#E4E4E4;
            @media (max-width:768px) {
                font-size:14rem;
            }
        }
    }
`

export const PerformWrapper = styled(Flex)`${({ $isReverse }) => css`
    height:400rem;
    @media (max-width:768px) {
                height:200rem;
    }
    img{
        position:absolute;
        width:100%;
        height:400rem;
    @media (max-width:768px) {
                height:200rem;
    }
        object-fit:cover;
    }

    >div{
        width:100%;
        height:100%;
        padding:40rem;
            @media (max-width:768px) {
            padding:20rem;
            }
        z-index:1;
        h4{
            font-size:40rem;
            font-weight:500;
            color:#fff;
            margin-bottom:12rem;
            @media (max-width:768px) {
                font-size:20rem;
            }
        }
        p{
            font-size:18rem;
            font-weight:500;
            text-align:${$isReverse ? "right" : "left"};
            color:#E4E4E4;
            @media (max-width:768px) {
                font-size:14rem;
                white-space:pre-line;
            }
        }
    }
`}
`

export const GridWrapper = styled(Grid)`
    @media (min-width:769px){
        width:1200rem;
        height:800rem;
        grid-template-columns: repeat(3,1fr);
        margin-bottom:180rem;
    }
    @media (max-width:768px) {
            width:calc(100% - 32rem);
            grid-template-rows: repeat(3,1fr);
            height:1500rem;
            margin-bottom:40rem;
    }
`

export const PerformGridWrapper = styled(Grid)`
    @media (min-width:769px){
        width:1200rem;
        margin-bottom:180rem;
    }
    @media (max-width:768px) {
        padding:0 16rem;
        margin-bottom:40rem;
    }


`