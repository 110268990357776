import React from "react";
import { BoxWrapper } from "../styled.Main.Inquiry";
import { Flex, Icon } from "Common/UI/Atoms";
import InquiryList from "./InquiryList";
import Map from "Pages/Companys/Pages/Map/Components/Map";
import { Link } from "react-router-dom";
import { Svg } from "Common/UI/Atoms/Icon/styled.Icon";
import { CUSTOMER_INQUIRY } from "Pages/Customer/Route.Customer";

const InquiryBox = ({ display: { mobile } = {} }) => {
    return (
        <BoxWrapper column={mobile}>
            <Flex height={mobile ? undefined : "100%"} fill>
                <Map width={"100%"} height={mobile ? 300 : "100%"} />
            </Flex>
            <Flex valign={"top"} height={"100%"} fill>
                <Flex
                    padding={mobile ? [20, 12] : [40, 60]}
                    className="infoBox"
                    column
                >
                    <InquiryList
                        icon={<Icon.Company />}
                        title={"Company"}
                        info={"(주)승진기업"}
                    />
                    <InquiryList
                        icon={<Icon.Address />}
                        title={"Address"}
                        info={
                            "(54149) 전라북도 군산시 미성로 503, 102호 (미룡동)"
                        }
                    />
                    <InquiryList
                        icon={<Icon.Tel />}
                        title={"Tel"}
                        info={"063-471-7403"}
                    />
                    <InquiryList
                        icon={<Icon.Tel />}
                        title={"Phone"}
                        info={"010-8776-7403"}
                    />
                    <InquiryList
                        icon={<Icon.Fax />}
                        title={"Fax"}
                        info={"063-471-7404"}
                    />
                </Flex>
                <Link to={CUSTOMER_INQUIRY} className="link">
                    <Flex gap={12}>
                        <Icon.Sns fill />
                        <p>
                            <b>문의 사항</b>이 있으신가요?
                        </p>
                    </Flex>
                    <Svg $width={51} viewBox="0 0 51 12" fill="none">
                        <path d="M0 11H50L42.9167 1" stroke="white" />
                    </Svg>
                </Link>
            </Flex>
        </BoxWrapper>
    );
};

export default InquiryBox;
