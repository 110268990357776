import React from "react";
import { BodyWrapper } from "../styled.Performance.Photograph";
import { Flex } from "Common/UI/Atoms";
import { Link } from "react-router-dom";

const PhotographBody = ({ id, title, company, url, date }) => {
    return (
        <BodyWrapper>
            <Flex className="images" height={326}>
                <img src={url} />
                <Link to={"./" + id}>Detail View</Link>
            </Flex>
            <Flex className="comment" padding={[30, 24]} column gap={24}>
                <h3>{title}</h3>
                <Flex gap={16}>
                    <p>{company}</p>
                    <p>{date}</p>
                </Flex>
            </Flex>
        </BodyWrapper>
    );
};

export default PhotographBody;
