import React from "react";
import { Wrapper } from "../styled.Modal";
import { Flex, Overlay } from "Common/UI/Atoms";

/**
 *
 * @param {{
 * size:500|700|740|900|1200|number
 * children:JSX.Element
 * isActive:boolean
 * padding:number[]
 * cancelText:string|false
 * successText:string|false
 * column:boolean
 * onClose:() => void
 * onSuccess?:() => void
 * }} param0
 * @returns
 */
const ModalBox = ({
    isActive,
    size = 500,
    padding,
    children,
    cancelText = "취소",
    successText = "확인",
    column,
    onClose = () => {},
    onSuccess,
}) => {
    const timer = 300;
    return (
        <Overlay timer={timer} isActive={isActive}>
            <Wrapper
                $timer={timer}
                $isActive={isActive}
                $size={size}
                $padding={padding}
            >
                <Flex className={"modalContent"} column={column} gap={0}>
                    {children}
                </Flex>
                <Flex className={"modalFooter"}>
                    <Flex fill></Flex>
                    <Flex gap={40}>
                        {cancelText ? (
                            <button onClick={() => onClose(false)}>
                                {cancelText}
                            </button>
                        ) : undefined}
                        {successText ? (
                            <button
                                disabled={!onSuccess}
                                onClick={() => onSuccess()}
                                Theme={"primary"}
                            >
                                {successText}
                            </button>
                        ) : undefined}
                    </Flex>
                </Flex>
            </Wrapper>
        </Overlay>
    );
};

export default ModalBox;
