import axios from "axios";
import { requestApi } from "./Config";
import { SERVER_URL } from "./env";

export const fileUpload = async (config) => {

    const formData = new FormData();
    for (let name in config) {
        formData.append(name, config[name]);
    }

    const response = await axios.post(SERVER_URL + 'upload/index.php', formData)
    return response
}
export const mail = async (config) =>
    requestApi('mail', config);


// {
//     const formData = new FormData();
//     for (let name in config) {
//         formData.append('file', config[name]);
//     }
//     const response = await axios.post(SERVER_URL + 'upload/index.php')
//     return { response }
// }