import { Flex, Grid } from "Common/UI/Atoms";
import { css, styled } from "styled-components";

export const BodyWrapper = styled(Flex).attrs({
    column: true,
    gap: 32,
})`

            @media (max-width:768px){
                .images{

                    height:184rem;
                }
            }
    img{
        width:100%;
        height:100%;
        object-fit:cover;
    }
    a{
        position:absolute;
        width:520rem;
        
        height:60rem;
        padding:0 60rem;
        @media (max-width:768px){
            width:288rem;
        height:48rem;
        padding:0 16rem;
        }
        display:flex;
        align-items:center;
        background-color:#333;
        color:#fff;
        top:100%;
        transform:translateY(-50%);
        font-size:16rem;
        font-weight:500;
        &:hover{
            background-color:#2044AC ;
        }
    }
    h3{
        font-size:22rem;
        font-weight:600;
        color:#333;
        @media (max-width:768px){
        font-size:18rem;
        }
    }
    p{
        font-size:16rem;
        color:#454545;
        @media (max-width:768px){
        font-size:14rem;
        }
    }
    .comment{
        
        @media (max-width:768px){
        padding:24rem 20rem;
            gap:12rem;
        }
    }
`

export const AdminWrapper = styled(Flex).attrs({ margin: [0, 0, 20] })`

            @media (max-width:768px){
        width:100%;
    }
    a,button{
        color:#fff;
        background-color:#333 ;
        padding:6rem 12rem;
        border-radius:8rem;
    }
`

export const TitleInput = styled.input`
    font-size:36rem;
    color:#333333;
    font-weight:600;
    text-align:center;
            @media (max-width:768px){
    font-size:24rem;
            }
`

export const SubWrapper = styled(Flex)`
margin:20rem 0;
width:1200rem;
            @media (max-width:768px){
        width:100%;

            }
        padding:0 0 40rem ;
        border-bottom:1rem solid #555;
    input{
        width:200rem;
        font-size:20rem;
        color:#454545;
            @media (max-width:768px){
                flex:1;
                width:50%;
        font-size:14rem;
            }
    }
    p{
        font-size:20rem;
        color:#454545;
            @media (max-width:768px){
        font-size:14rem;
            }

    }
    span{
        width:1rem;
        height:12rem;
        background-color:#CCCCCC;
    }
`

export const LabelWrapper = styled.label`
    border:1rem solid #555 ;
    color:#555;
    font-size:20rem;
    padding:12rem 8rem;
    border-radius:8rem;
    text-align:center;
    input{
        display:none ;
    }
`
export const ImageWrapepr = styled.div`${({ $isEdit }) => css`

    width:1200rem;
    position:relative ;
            @media (max-width:768px){
                width:100%;
            }
            img{
                width:100%;
            }
            .comment{
        width:100%;
        height:100%;
        left:0;
        top:0;
        background-color:rgba(0,0,0,0.5);
        position:absolute;
        padding:40rem 32rem;
            @media (max-width:768px){
        padding:20rem 12rem;
            }
        ${$isEdit ? '' : css`opacity:0;
            transition:opacity 0.3s;
        ` }
        button{
            position: absolute;
            right:20rem;
            top:20rem;
        }
        textarea,p{
            color:#E4E4E4;
            font-size:20rem;
            height:min-content;
            appearance:none;
            resize:none;
            &::placeholder{
                color:#ddd;
            }
        }
    }
        ${$isEdit ? "" : css`&:hover{
            .comment{
                opacity:1 ;
            }
        }` }
`}
`

export const ViewWrppaer = styled(Flex)`
    
            @media (max-width:768px){
        width:100%;
                padding:0 16rem;
                margin:0 0 40rem;
            }
`

export const ListWrapper = styled(Grid)`
            @media (min-width:769px){
                grid-template-columns: repeat(2,1fr);
                gap:60rem 40rem;
            }   
            @media (max-width:768px){
        width:100%;
                padding:0 16rem;
                margin:0 0 40rem;
            }

`